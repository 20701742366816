import { Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Avatar, AvatarProps } from '../../../cc-ui';
import { makeProfileAvatarUrl } from '../../../utilities/utils';
import { Member, MemberCallback } from './types';

const List = styled('ul')`
  list-style: 'none';
  padding: 0;
`;

const ListItem = styled('li')`
  display: grid;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  padding: 0.5rem;
  grid-template-columns: auto 1fr auto;
  column-gap: 1rem;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'avatar primary-text   actions'
    'avatar secondary-text actions';

  :hover {
    background-color: #fcfcfc;
    box-shadow: 0 0 2px #e0e0e0;
  }
`;

const ListItemAvatar = styled('div')`
  grid-area: avatar;
`;

const ListItemPrimaryText = styled(Typography)`
  grid-area: primary-text;
  font-weight: semi-bold;
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
`;

const ListItemSecondaryText = styled(Typography)`
  grid-area: secondary-text;
  font-size: 0.85rem;
  color: #757575;
`;

const ListItemActions = styled('div')`
  grid-area: actions;
`;

export interface UserListProps {
  /** @description what buttons should exist for each user in the list? */
  actions: (user: Member) => JSX.Element;
  users: Member[];
  onClick?: MemberCallback;
}

export const UserList = ({ actions, users, onClick = () => {} }: UserListProps) => (
  <List>
    {users.map((user) => {
      const { id, firstName, lastName, isFacilitator, roles = [], isVetted } = user;
      const name = `${firstName} ${lastName}`;
      const approvalStatus = isVetted ? 'Approved' : 'Pending Approval';
      const avatarProps: AvatarProps = {
        alt: name,
        title: `${name} • ${approvalStatus}`,
        tooltipTitle: `${name} • ${approvalStatus}`,
      };
      const avatarOutlineColor =
        isFacilitator || roles.includes('Admin')
          ? 'primary'
          : isVetted || roles.includes('Educator')
          ? 'success'
          : 'error';
      const subtitle = isFacilitator
        ? `Facilitator${roles?.length ? `, ${roles.join(', ')}` : ''}`
        : roles?.length
        ? roles.join(', ')
        : '';
      return (
        <ListItem data-testid="user-list-item" key={id} onClick={() => onClick(user)}>
          <ListItemAvatar>
            <Avatar color={avatarOutlineColor} src={makeProfileAvatarUrl(id)} {...avatarProps} />
          </ListItemAvatar>
          <ListItemPrimaryText>{name}</ListItemPrimaryText>
          <ListItemSecondaryText data-testid="secondary-text">{subtitle}</ListItemSecondaryText>
          <ListItemActions
            onClick={(event) => {
              // list item actions click should not bubble up
              event.stopPropagation();
            }}
          >
            <Chip
              color={isVetted ? 'success' : 'warning'}
              label={approvalStatus}
              size="small"
              sx={{ marginRight: 1 }}
            />
            {actions(user)}
          </ListItemActions>
        </ListItem>
      );
    })}
  </List>
);
