import { ProfileDto } from '@crowdcoursing/api/openapi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { deleteProfilePicture, saveAddress, savePersonalInfo, saveProfilePicture } from '../actions/profile';
import { makeProfileAvatarUrl } from './../utilities/utils';
import { User } from './state';

function makeUser(profile: ProfileDto): User {
  return {
    avatarUrl: makeProfileAvatarUrl(profile.userDto!.id!),
    city: profile.userDto?.city || '',
    email: profile.userDto!.email || '',
    firstName: profile.userDto!.firstName || '',
    id: profile.userDto!.id!,
    isAdministrator: profile.userDto!.isAdministrator!,
    isDailyNotificationsOn: profile.userDto!.isDailyNotificationsOn!,
    isEducator: profile.userDto!.isEducator!,
    isProfileReady: profile.isProfileReady!,
    isWeeklyNotificationsOn: profile.userDto!.isWeeklyNotificationsOn!,
    lastName: profile.userDto!.lastName || '',
    line1: profile.userDto?.addressLine1 || '',
    line2: profile.userDto?.addressLine2 || '',
    phoneNumber: profile.userDto!.phoneNumber!,
    state: profile.userDto?.state || '',
    zipCode: profile.userDto!.zipCode || '',
  };
}

const initialState = null as User | null;

const forceNewProfilePicture = (user: User | null) => {
  if (!user) {
    return;
  }
  // set a arbitrary query string to trigger re-rendering of the new
  // profile picture
  user.avatarUrl = `${makeProfileAvatarUrl(user.id)}?t=${new Date().getTime()}`;
};

const slice = createSlice({
  // respond to actions defined elsewhere
  extraReducers: (builder) =>
    builder
      .addCase(savePersonalInfo.fulfilled, (state, action) => ({ ...state!, ...action.payload }))
      .addCase(saveAddress.fulfilled, (state, action) => ({ ...state!, ...action.payload }))
      .addCase(saveProfilePicture.fulfilled, forceNewProfilePicture)
      .addCase(deleteProfilePicture.fulfilled, forceNewProfilePicture),
  initialState,
  name: 'user',
  reducers: {
    logout() {},
    whoamiSuccess(_, action: PayloadAction<{ user: ProfileDto }>) {
      const u = makeUser(action.payload.user);
      return u;
    },
  },
});

export const actions = slice.actions;
export const userReducer = slice.reducer;
