import { Delete as DeleteIcon, MoreVert } from '@mui/icons-material';
import { Avatar, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { pageDialogOpen } from '../../../state/app';
import { Organization } from './types';

const tDeleteOrganizationTooltip = 'Opens the delete organization confirmation modal.';
const tDelete = 'Delete';

interface OrganizationItemProps {
  organization: Organization;
  isSiteAdmin: boolean;
}

const OrganizationItem = ({ organization, isSiteAdmin }: OrganizationItemProps) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    handleMenuClose();
    dispatch(
      pageDialogOpen({
        contentProps: { organizationId: organization.id, organizationName: organization.name },
        contentType: 'delete-organization',
      })
    );
  };

  return (
    <Grid alignItems="center" container direction="row" gap={{ md: '2.5rem', sm: '1.5rem', xs: '1rem' }} item>
      <Grid container direction="row" item xs>
        <Grid item>
          <Avatar
            src={organization.avatarUrl}
            sx={{
              height: { md: '3.5rem', sm: '3rem', xs: '2.5rem' },
              width: { md: '3.5rem', sm: '3rem', xs: '2.5rem' },
            }}
          />
        </Grid>
        <Grid item marginLeft={{ md: '1em', sm: '0.75em', xs: '0.5em' }}>
          <Typography
            sx={{
              fontSize: { md: '1.25rem', sm: '1rem', xs: '0.9rem' },
            }}
            variant="h6"
          >
            {organization.name}
          </Typography>
          <Typography
            sx={{
              fontSize: { md: '0.875rem', sm: '0.8125rem', xs: '0.75rem' },
            }}
            variant="body2"
          >
            {organization.address}
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ display: { sm: 'block', xs: 'none' } }}>
        <Typography
          sx={{
            fontSize: { md: '0.875rem', sm: '0.8125rem', xs: '0.75rem' },
          }}
          variant="subtitle2"
        >
          {organization.isExternal ? 'External' : 'Internal'}
        </Typography>
      </Grid>
      {isSiteAdmin && (
        <Grid item>
          <Grid alignItems="center" container>
            <IconButton onClick={handleMenuClick} size="small">
              <Tooltip placement="top-start" title={tDeleteOrganizationTooltip}>
                <MoreVert fontSize="small" />
              </Tooltip>
            </IconButton>
            <Menu anchorEl={anchorEl} onClick={handleMenuClose} onClose={handleMenuClose} open={Boolean(anchorEl)}>
              <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                <DeleteIcon sx={{ fontSize: 'small', mr: 1 }} />
                {tDelete}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { OrganizationItem };
