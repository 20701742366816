import { useState } from 'react';

import CreateOrganization from '../../../components/Organizations/CreateOrganization';
import Dialog from '../../atoms/Dialog/Dialog';

const tCreateNewOrganization = 'Create New Organization';

interface CreateOrganizationDialogProps {
  onClose: () => void;
}

export const CreateOrganizationDialog = ({ onClose }: CreateOrganizationDialogProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Dialog
      actions={<CreateOrganization onClose={handleClose} />}
      dialogType="custom"
      onDialogClose={handleClose}
      open={isOpen}
      title={tCreateNewOrganization}
    />
  );
};
