import { useOrganizationImageUrl } from '~hooks/useOrganizationImageUrl';

import { OrganizationItem } from './OrganizationItem';
import { Organization } from './types';

interface OrganizationItemWithAvatarProps {
  organization: Organization;
  isSiteAdmin: boolean;
}

export const OrganizationItemWithAvatar = ({ organization, isSiteAdmin }: OrganizationItemWithAvatarProps) => {
  const avatarUrl = organization.id ? useOrganizationImageUrl(organization.id) : '';
  return <OrganizationItem isSiteAdmin={isSiteAdmin} organization={{ ...organization, avatarUrl }} />;
};
