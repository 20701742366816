import { useNavigate } from 'react-router-dom';

import { leave, removeMember, requestMembership } from '../../actions/events';
import { MemberActionButton } from '../../cc-ui';
import { MemberActionButtonProps } from '../../cc-ui/components/MemberActionButton/MemberActionButton';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';
import { Route } from '../../services/router/Route';

const tManageSession = 'Members';
const tJoinSession = 'Join';
const tPending = 'Request Pending';
const tLeaveSessionSelf = 'Leave';
const tTitleFacilitator = 'Remove from $eventName';
const tTitleSelf = 'Leave $eventName';
const tBodyFacilitator =
  'Removing a member from $eventName cannot be undone. The member will have to request membership and await the approval process if they want to rejoin this event. Enter "confirm" in the text field below if you wish to continue with this action.';
const tBodySelf =
  'Leaving $eventName cannot be undone. You will have to request membership and await the approval process if you want to rejoin this event. Enter "confirm" in the text field below if you wish to continue with this action.';

interface EventActionButtonProps {
  onJoinRequested?: () => void;
  onLeaveRequested?: () => void;
  isFacilitator: boolean;
  isEventOwner: boolean;
  isMember: boolean;
  isPending: boolean;
  name: string;
  eventId: string;
}

const EventActionButton = ({
  isFacilitator,
  isEventOwner,
  isMember,
  isPending,
  name,
  eventId,
  onJoinRequested,
  onLeaveRequested,
}: EventActionButtonProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user!);
  const userId = user.id;

  const tBody = isFacilitator ? tBodyFacilitator : tBodySelf;
  const tLeavePod = tLeaveSessionSelf;
  const tTitle = isFacilitator ? tTitleFacilitator : tTitleSelf;

  const btnProps: MemberActionButtonProps = {
    isFacilitator,
    isMember,
    isPending,
    isRoomLeader: isEventOwner,
    join: {
      onClick: async () => {
        // If user is incomplete
        if (
          user?.firstName === undefined ||
          user?.firstName === '' ||
          user?.firstName === null ||
          user?.lastName === undefined ||
          user?.lastName === '' ||
          user?.lastName === null ||
          user?.zipCode === undefined ||
          user?.zipCode === '' ||
          user?.zipCode === null ||
          user?.email === undefined ||
          user?.email === '' ||
          user?.email === null ||
          user?.phoneNumber === undefined ||
          user?.phoneNumber === '' ||
          user?.phoneNumber === null
        ) {
          navigate('/profile');
          return;
        }
        await dispatch(requestMembership({ eventId, userId }));
        onJoinRequested && onJoinRequested();
      },
      text: tJoinSession,
    },
    leave: {
      body: tBody.replaceAll('$eventName', name),
      onConfirm: async () => {
        if (isFacilitator) {
          await dispatch(removeMember({ eventId, userId }));
        } else {
          await dispatch(leave({ eventId, userId }));
        }
        onLeaveRequested && onLeaveRequested();
        navigate(Route.home());
      },
      text: tLeavePod,
      title: tTitle.replaceAll('$eventName', name),
    },
    manage: {
      route: Route.eventMembers({ eventId }),
      text: tManageSession,
    },
    pendingText: tPending,
  };
  return <MemberActionButton {...btnProps} />;
};

export default EventActionButton;
