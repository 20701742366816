import { styled, useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useRef } from 'react';

import { Browser } from '../../../utilities/browser';

export const DROPZONE_ID = 'CrowdCoursing-dropzone';

export const Form = styled('form')(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.crowdCoursing.ORANGE['100']?.lighter ?? 'initial',
  },
  border: `1px solid ${theme.palette.crowdCoursing.ORANGE['100']?.main ?? 'initial'}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  position: 'relative',
}));

export interface DropzoneProps {
  children?: React.ReactNode;
  onChange: (files: FileList | null) => void;
  onSubmit: () => void;
  slotProps?: {
    form?: {
      style?: React.CSSProperties;
    };
  };
}

export const Dropzone = ({ children, onChange, onSubmit: onSubmitCallback, slotProps }: DropzoneProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLFormElement>(null);
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // * prevent drag and drop everywhere in the app
    // * allow drag and drop in `CrowdCoursing-dropzone`
    const doNothing = (event: any) => {
      const root = document.getElementById(DROPZONE_ID);
      const isInDropzone = root?.id === event.target.id || (root?.contains(event.target) ?? false);
      if (!isInDropzone) {
        event.stopPropagation();
        event.preventDefault();
        if (event?.dataTransfer) {
          event.dataTransfer.dropEffect = 'none';
        }
        return false;
      }
    };
    window.addEventListener('dragover', doNothing);
    window.addEventListener('drop', doNothing);

    return () => {
      window.removeEventListener('dragover', doNothing);
      window.removeEventListener('drop', doNothing);
    };
  }, []);

  const onMouseEnter = () => {
    if (ref.current) {
      ref.current.style.backgroundColor = theme.palette.crowdCoursing.ORANGE['100']?.lighter ?? 'initial';
    }
  };

  const onMouseLeave = () => {
    if (ref.current) {
      ref.current.style.backgroundColor = 'initial';
    }
  };

  const manageHoverStyle = useCallback(() => {
    if (!timeout.current) {
      onMouseEnter();
      timeout.current = setTimeout(() => {
        onMouseLeave();
        timeout.current = undefined;
      }, 250);
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onMouseLeave();
        timeout.current = undefined;
      }, 250);
    }
  }, [timeout]);

  const onDragOver = (event: React.DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    manageHoverStyle();
  };

  const onDragStart = (event: React.DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { type, name } = event.dataTransfer.files[0];
    event.dataTransfer.setData(type, name);
  };

  const onDrop = (event: React.DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    onChange(event.dataTransfer.files);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmitCallback();
  };

  const dropzoneHandlers = { onDragOver, onDragStart, onDrop, onMouseEnter, onMouseLeave, onSubmit };

  return Browser.hasDragAndDropEvents ? (
    <Form draggable encType="multipart/form-data" id={DROPZONE_ID} ref={ref} {...dropzoneHandlers} {...slotProps?.form}>
      {children}
    </Form>
  ) : (
    <Form encType="multipart/form-data" id={DROPZONE_ID} onSubmit={onSubmit} {...slotProps?.form}>
      {children}
    </Form>
  );
};
