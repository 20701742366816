import { Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.crowdCoursing.GREY['800']?.main ?? 'inherit',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  // some italics characters need a little extra space so they don't get cut off
  width: 'calc(100% + 0.125ch)',
}));

const tNoFile = 'No File Chosen';

const getFileNames = (files: FileList) => {
  const res = [];
  for (let i = 0; i < files.length; i++) {
    res.push(files[i].name);
  }
  return res;
};

interface WrappedFileNameProps {
  fileNames: string[];
}

const WrappedFileName: FC<WrappedFileNameProps> = ({ fileNames }) => {
  const fileName = fileNames.join(',');
  return (
    <Tooltip arrow title={fileName}>
      <Text data-testid="file-upload-filename" fontWeight="bold" variant="body2">
        {fileName}
      </Text>
    </Tooltip>
  );
};

export interface FileNameProps {
  files?: FileList | null;
}

export const FileName: FC<FileNameProps> = ({ files }) => {
  if (!files || files.length === 0) {
    return (
      <Text data-testid="file-upload-no-file" fontStyle="italic">
        {tNoFile}
      </Text>
    );
  }

  if (files.length === 1) {
    return <WrappedFileName fileNames={[files[0].name]} />;
  }

  if (files.length > 1) {
    return <WrappedFileName fileNames={getFileNames(files)} />;
  }

  return (
    <Text data-testid="file-upload-no-file" fontStyle="italic">
      {tNoFile}
    </Text>
  );
};
