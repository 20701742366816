import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_ROOM_OR_EVENT_NAME = 'roomOrEventName';

const tLabel = 'Name';
const tLengthMax = 'Cannot exceed 255 characters';
const tLengthMin = 'Must be at least 2 characters';

export type RoomOrEventNameProps = ConstructedTextField;

export const RoomOrEventName = ({ textFieldProps, ...props }: RoomOrEventNameProps) => {
  return (
    <TextField
      name={FIELD_NAME_ROOM_OR_EVENT_NAME}
      textFieldProps={{
        'data-cy': 'form-field-room-or-event-name',
        inputProps: { maxLength: 255, minLength: 2 },
        label: tLabel,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (meta?.dirty && value) {
          if (value.length < 2) {
            return tLengthMin;
          }

          if (value.length > 255) {
            return tLengthMax;
          }
        }

        return '';
      }}
      {...props}
    />
  );
};
