import { useNavigate, useParams } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { addFacilitator, addMember, addOwner, rejectMember, removeFacilitator, removeMember } from '../../actions/pods';
import { useSelector } from '../../hooks';
import { useRoomMemberManagementQuery, useRoomNameQuery } from '../../services/graphql/generated/react-query';
import MemberManagement, { MemberManagementContainerProps } from '../Shared/MemberManagement';

const tHeading = 'Member Management';
const tTitle = 'Remove from $name';
const tBody =
  'Removing a member from $name cannot be undone. The member will have to request membership and await the approval process if they want to rejoin this Room.';
const tActionText = 'Remove From Room';

const PodMembers = () => {
  const navigate = useNavigate();
  const isOwner = useSelector((state) => (state.app.selectedPod?.ownerId ?? 0) === (state.user?.id ?? 1));
  const { roomId } = useParams();
  const { isLoading, data: name } = useRoomNameQuery({ id: roomId! }, { select: (data) => data.room?.name });

  if (isLoading || !name) return null;

  const body = { roomId: roomId! };
  const mgmtProps: MemberManagementContainerProps = {
    addFacilitatorRequestActionCreator: (userId) => addFacilitator({ userId, ...body }),
    addOwnerRequestActionCreator: (userId) =>
      addOwner({
        body: { userId, ...body },
        onSuccess() {
          navigate(Route.podView({ roomId: roomId! }));
        },
      }),
    approveRequestActionCreator: (userId) => addMember({ userId, ...body }),
    heading: tHeading,
    isOwner,
    query: () =>
      useRoomMemberManagementQuery(
        { id: roomId! },
        {
          select: (data) => {
            const facilitators = data.room?.facilitators.map(({ id }) => id) ?? [];
            return {
              ...data.room,
              members:
                data.room?.members
                  .filter((member) => member.id !== data?.room?.owner.id)
                  .map((member) => ({
                    ...member,
                    isFacilitator: facilitators.includes(member.id),
                  })) ?? [],
              requests: data.room?.requests ?? [],
            };
          },
        }
      ),
    rejectRequestActionCreator: (userId) => rejectMember({ userId, ...body }),
    removeDialog: {
      actionText: tActionText,
      body: tBody.replace('$name', name),
      title: tTitle.replace('$name', name),
    },
    removeFacilitatorRequestActionCreator: (userId) => removeFacilitator({ userId, ...body }),
    removeMemberActionCreator: (userId) => removeMember({ userId, ...body }),
    roomId,
    subHeading: name,
  };
  return <MemberManagement {...mgmtProps} />;
};

export default PodMembers;
