import { TextField, TextFieldProps } from '@mui/material';
import { VFC } from 'react';

import { Series } from './SeriesList';

const tSeriesForm = 'series-form';
const tSeriesName = 'Series Name';
const tSeriesNameId = 'series-name';
const tSeriesDescription = 'Series Description';
const tSeriesDescriptionId = 'series-description';

export interface SeriesFormProps {
  descriptionProps?: TextFieldProps;
  nameProps?: TextFieldProps;
  onSubmit?: (f: Partial<Series>) => void;
}

export const SeriesForm: VFC<SeriesFormProps> = ({ descriptionProps = {}, nameProps = {}, onSubmit = () => {} }) => {
  const onFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target as HTMLFormElement);
    onSubmit({
      description: formData.get(tSeriesDescriptionId)?.toString(),
      name: formData.get(tSeriesNameId)?.toString(),
    });
  };

  return (
    <form id={tSeriesForm} name={tSeriesForm} onSubmit={onFormSubmit} style={{ display: 'grid', gap: '3rem' }}>
      <TextField
        data-testid="name-field"
        fullWidth
        id={tSeriesNameId}
        label={tSeriesName}
        name={tSeriesNameId}
        {...nameProps}
      />
      {/* <Description /> */}
      <TextField
        data-testid="form-field-description"
        fullWidth
        id={tSeriesDescriptionId}
        label={tSeriesDescription}
        multiline
        name={tSeriesDescriptionId}
        rows={3}
        {...descriptionProps}
      />
    </form>
  );
};
