import { FC } from 'react';

import { TableCell as BaseTableCell, TableCellProps as BaseTableCellProps } from '../TableCell';

export interface TableCellBodyProps {
  value: BaseTableCellProps['value'];
  allowWrap?: boolean;
}

export const TableCellBody: FC<TableCellBodyProps> = ({ value, allowWrap = false }) => {
  return (
    <BaseTableCell
      tableCellProps={{
        sx: {
          padding: '12px 16px',
          whiteSpace: allowWrap ? 'normal' : 'nowrap',
          width: 'auto',
        },
      }}
      typographyProps={{
        noWrap: !allowWrap,
        variant: 'body2',
      }}
      value={value}
    />
  );
};

export interface TableCellHeadProps {
  value: BaseTableCellProps['value'];
  allowWrap?: boolean;
}

export const TableCellHead: FC<TableCellHeadProps> = ({ value, allowWrap = false }) => {
  return (
    <BaseTableCell
      tableCellProps={{
        sx: {
          padding: '12px 16px',
          whiteSpace: allowWrap ? 'normal' : 'nowrap',
          width: 'auto',
        },
      }}
      typographyProps={{
        noWrap: !allowWrap,
        sx: {
          fontWeight: 'bold',
        },
        variant: 'overline',
      }}
      value={value}
    />
  );
};

export const EmptyTableCell: FC = () => <BaseTableCell tableCellProps={{ colSpan: 6 }} />;
