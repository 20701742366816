import { Box, Button, styled, Tooltip, Typography } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { useCallback, useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';

import { EventRuleDto } from '../../services/openapi/generated/CrowdCoursingSchemas';
import {
  CheckboxValue,
  DateRange,
  DateRangeValue,
  FIELD_NAME_DATE_RANGE,
  FIELD_NAME_TIME_RANGE,
  Form,
  formatSelectValue,
  FormProps,
  getParsedSelected,
  getParsedSelectedMultiple,
  SelectValueMultiple,
  SelectValueSingle,
  SubmitButton,
  SwitchValue,
  TextFieldValue,
  TimeRange,
  TimeRangeValue,
} from '../../ui/atoms/Form';
import {
  BubbleSelect,
  BubbleSelectProps,
  Capacity,
  CreateMeetingTimes,
  Description,
  EventSeriesSelect,
  FIELD_NAME_BUBBLE_SELECT,
  FIELD_NAME_CAPACITY,
  FIELD_NAME_CREATE_MEETING_TIMES,
  FIELD_NAME_DESCRIPTION,
  FIELD_NAME_EVENT_SERIES_SELECT,
  FIELD_NAME_IS_AUTOJOIN,
  FIELD_NAME_IS_SEARCHABLE,
  FIELD_NAME_MEETING_DAYS_SELECT,
  FIELD_NAME_ROOM_OR_EVENT_NAME,
  IsAutojoin,
  IsSearchable,
  MeetingDaysSelect,
  RoomOrEventName,
} from '../../ui/molecules/Form';
import { dayValues } from '../../ui/molecules/Form/MeetingDaysSelect/MeetingDaysSelect';
import { ManageSeriesSidebar, ManageSeriesSidebarProps } from '../Pods/Series';
import { FIELD_NAME_RULES, RulesValue, SessionFormRules } from './EventFormRules';
import { MeetingTime, MeetingTimeTable } from './MeetingTimeTable';

const tEditMeetingTimes = 'Meeting times';
const tManageSeries = 'Manage Room Series';

const tCreateSession = 'Create';
const tEditSession = 'Update';
const tRequiredField = '* Required Fields';
const tAutojoinTooltip = 'Toggle ON to allow people to join your event without needing their request approved.';
const tSearchableTooltip = 'Toggle ON to make your event searchable.';
const tMeetingTimesSubtitle = 'Event times show when the event occurs';

const ColumnContainer = styled(Box)(({ theme }) => {
  return {
    display: 'grid',
    gap: '3rem',
    gridAutoFlow: 'column',
    [theme.breakpoints.down('md')]: {
      gridAutoFlow: 'row',
    },
  };
});

interface FormValues {
  [FIELD_NAME_IS_AUTOJOIN]: SwitchValue;
  [FIELD_NAME_IS_SEARCHABLE]: SwitchValue;
  [FIELD_NAME_CREATE_MEETING_TIMES]: CheckboxValue;
  [FIELD_NAME_EVENT_SERIES_SELECT]: SelectValueSingle;
  [FIELD_NAME_RULES]: RulesValue[];
  /** required */
  [FIELD_NAME_ROOM_OR_EVENT_NAME]: TextFieldValue;
  [FIELD_NAME_DESCRIPTION]: TextFieldValue;
  /** required if createMeetingTimesSelected */
  [FIELD_NAME_DATE_RANGE]: DateRangeValue;
  [FIELD_NAME_TIME_RANGE]: TimeRangeValue;
  [FIELD_NAME_MEETING_DAYS_SELECT]: SelectValueMultiple;
  [FIELD_NAME_CAPACITY]: TextFieldValue;
  [FIELD_NAME_BUBBLE_SELECT]: SelectValueSingle;
  /** hack */
  touched?: boolean;
}

const requiredFields: Array<keyof FormValues> = ['roomOrEventName', 'description'];

interface ScheduleInput {
  beginDate: string;
  beginMeetingTime: string;
  capacity: number;
  endDate: string;
  endMeetingTime: string;
  seriesId?: string;
  /** one letter per day e.g. 'MWF' */
  days: number[];
}

export interface EventInput {
  name: string;
  description: string;
  isOnline: boolean;
  isAutoJoin: boolean;
  schedule?: ScheduleInput;
  meetingTimes: MeetingTime[];
  eventRules?: EventRuleDto[];
  bubbleId: string;
}

export interface SessionFormProps
  extends Omit<ManageSeriesSidebarProps, 'label' | 'roomId' | 'refetch'>,
    Partial<Omit<EventInput, 'schedule'>> {
  room?: {
    id: ManageSeriesSidebarProps['roomId'];
    refetch: ManageSeriesSidebarProps['refetch'];
  };
  onSubmit: (s: EventInput) => Promise<void>;
  bubbleSelectItems: BubbleSelectProps['selectItems'];
}

export const SessionForm = ({
  series,
  onSubmit,
  room,
  meetingTimes: defaultMeetingTimes,
  bubbleSelectItems,
  ...defaults
}: SessionFormProps) => {
  const [meetingTimes, setMeetingTimes] = useState(defaultMeetingTimes || []);
  const [createMeetingTimesSelected, setCreateMeetingTimesSelected] = useState(false);

  // parent might change the contents of this array
  useEffect(() => {
    defaultMeetingTimes && setMeetingTimes(defaultMeetingTimes);
  }, [defaultMeetingTimes]);

  const handleUpdateCapacity = (meetingTime: MeetingTime) => {
    const updatedMeetingTimes = meetingTimes.map((time) => (time.id === meetingTime.id ? meetingTime : time));
    setMeetingTimes(updatedMeetingTimes);
  };

  const changeSeries = (m: MeetingTime) => {
    const idx = meetingTimes.findIndex((x) => x.id === m.id);
    meetingTimes[idx] = m;
    setMeetingTimes(meetingTimes);
  };

  const remove = (m: MeetingTime) => {
    const idx = meetingTimes.findIndex((x) => x.id === m.id);
    setMeetingTimes([...meetingTimes.slice(0, idx), ...meetingTimes.slice(idx + 1)]);
  };

  const defaultBubbleId = bubbleSelectItems.length === 1 ? String(bubbleSelectItems[0].id) : undefined;
  const initialBubbleSelectValue = defaults.bubbleId
    ? bubbleSelectItems.find((x) => x.id === defaults.bubbleId)
    : undefined;

  const handleSubmit = useCallback<FormProps<FormValues>['onSubmit']>(
    async ({
      capacity,
      createMeetingTimes,
      dateRange,
      timeRange,
      description,
      eventSeriesSelect,
      isAutojoin,
      isSearchable,
      meetingDaysSelect,
      rules,
      roomOrEventName,
      bubbleSelect,
    }) => {
      if (!description || !roomOrEventName) {
        return;
      }
      rules = rules ?? [];
      const bubbleId = defaultBubbleId ?? (bubbleSelect ? String(getParsedSelected(bubbleSelect).id) : undefined);
      if (!bubbleId) throw new Error('Cannot create or update event without access to bubbles. How did you get here?');

      const request: EventInput = {
        bubbleId,
        description: description,
        eventRules: rules.map(({ rule, description }, index) => ({ description, name: rule, order: index })),
        isAutoJoin: Boolean(isAutojoin),
        isOnline: Boolean(isSearchable),
        meetingTimes,
        name: roomOrEventName,
      };

      if (createMeetingTimes && dateRange[0] && dateRange[1] && timeRange[0] && timeRange[1] && meetingDaysSelect) {
        request.schedule = {
          beginDate: dateRange[0].toISOString(),
          beginMeetingTime: timeRange[0].toISOString(),
          capacity: Number(capacity ?? 1),
          days: getParsedSelectedMultiple(meetingDaysSelect).map(({ id }) => id) as number[],
          endDate: dateRange[1].toISOString(),
          endMeetingTime: timeRange[1].toISOString(),
          seriesId: eventSeriesSelect && String(getParsedSelected(eventSeriesSelect).id),
        };
      }

      await onSubmit(request);
    },
    [meetingTimes, defaultBubbleId]
  );

  const saveBtnText = defaults.name ? tEditSession : tCreateSession;

  return (
    <Form<FormValues> mutators={{ ...arrayMutators }} onSubmit={handleSubmit}>
      <FormSpy<FormValues>
        render={({ values: { createMeetingTimes } }) => {
          useEffect(() => {
            setCreateMeetingTimesSelected(Boolean(createMeetingTimes));
          }, [createMeetingTimes]);
          return null;
        }}
      />

      <Box display="grid" gap="2rem" gridAutoColumns="minmax(0, auto)" gridAutoFlow="row">
        {!defaultBubbleId && (
          <BubbleSelect
            initialValue={initialBubbleSelectValue ? formatSelectValue(initialBubbleSelectValue) : undefined}
            required
            selectItems={bubbleSelectItems}
          />
        )}
        <ColumnContainer gridAutoColumns="1fr">
          <RoomOrEventName initialValue={defaults.name} />
          <Box display="grid" gap="3rem" gridAutoColumns="1fr" gridAutoFlow="column">
            <Tooltip placement="top" title={tAutojoinTooltip}>
              <span>
                <IsAutojoin
                  initialValue={defaults.isAutoJoin ? [FIELD_NAME_IS_AUTOJOIN] : undefined}
                  useDefault={false}
                />
              </span>
            </Tooltip>
            <Tooltip placement="top" title={tSearchableTooltip}>
              <span>
                <IsSearchable initialValue={defaults.isOnline ? [FIELD_NAME_IS_SEARCHABLE] : undefined} />
              </span>
            </Tooltip>
          </Box>
        </ColumnContainer>
        <Description initialValue={defaults.description} required />
        <Typography variant="body1">{tRequiredField}</Typography>

        <FormSpy<FormValues>
          render={({ form }) => {
            // TODO: less hacky way to integrate with react-final-form
            useEffect(() => form.registerField('touched', () => {}, { touched: true }), []);

            const markTouched = () => {
              form.change('touched', true);
              return true;
            };

            const handleUpdate = useCallback(
              (callback: (meetingTime: MeetingTime) => void) => (meetingTime: MeetingTime) => {
                markTouched();
                callback(meetingTime);
              },
              []
            );

            if (meetingTimes.length === 0) {
              return null;
            }

            return (
              <>
                <Typography marginBottom="2rem" variant="h5">
                  {tEditMeetingTimes}
                </Typography>
                <MeetingTimeTable
                  meetingTimes={meetingTimes}
                  onChangeCapacity={handleUpdate(handleUpdateCapacity)}
                  onChangeSeries={handleUpdate(changeSeries)}
                  onDelete={handleUpdate(remove)}
                  series={series}
                />
              </>
            );
          }}
        />

        <SessionFormRules rules={defaults.eventRules} />

        <CreateMeetingTimes />
        {!createMeetingTimesSelected && <Typography>{tMeetingTimesSubtitle}</Typography>}
        {createMeetingTimesSelected && (
          <>
            {!!room && (
              <ColumnContainer alignItems="center" gridAutoColumns="1.5fr 1fr">
                <EventSeriesSelect selectItems={series.map(({ id, name: value }) => ({ id, value }))} />
                <ManageSeriesSidebar
                  label={(onclick) => <Button onClick={onclick}>{tManageSeries}</Button>}
                  refetch={room.refetch}
                  roomId={room.id}
                  series={series}
                />
              </ColumnContainer>
            )}
            <DateRange dateRangePickerProps={{ disablePast: true }} required />
            <TimeRange required />
            <ColumnContainer alignItems="baseline" gridAutoColumns="minmax(0, 1fr)">
              <Capacity initialValue="1" required />
              <FormSpy<FormValues> subscription={{ values: true }}>
                {({ values: { dateRange }, form }) => {
                  useEffect(() => {
                    if (!dateRange) {
                      return;
                    }
                    const [beginDate, endDate] = dateRange;
                    if (!beginDate || !endDate) {
                      return;
                    }
                    // https://day.js.org/docs/en/display/difference
                    const numDaysInEvent = endDate.diff(beginDate, 'days', true) + 1;
                    const numUniqueDays = Math.min(numDaysInEvent, 7);
                    const uniqueDayIds = Array.from({ length: numUniqueDays }).map((_, i) => (beginDate.day() + i) % 7);
                    const uniqueDayValues = dayValues.filter(({ id }) => uniqueDayIds.includes(Number(id)));
                    form.change(FIELD_NAME_MEETING_DAYS_SELECT, uniqueDayValues.map(formatSelectValue));
                  }, [dateRange]);
                  return null;
                }}
              </FormSpy>
              <MeetingDaysSelect required />
            </ColumnContainer>
          </>
        )}

        <Box display="grid" gridAutoColumns="minmax(25%, fit-content)" gridAutoFlow="column" justifyContent="end">
          <SubmitButton
            requiredFields={[
              requiredFields,
              createMeetingTimesSelected && ['dateRange', 'meetingDaysSelect', 'timeRange', 'capacity'],
              !defaultBubbleId && ['bubbleSelect'],
            ].flatMap((fields) => (typeof fields === 'boolean' ? [] : fields))}
            sx={{ marginTop: '3rem' }}
          >
            {saveBtnText}
          </SubmitButton>
        </Box>
      </Box>
    </Form>
  );
};
