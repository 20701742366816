import { Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { ListContainer, ListContainerProps, RightSidebar, TaskList, TaskListProps } from '../../../cc-ui';
import { useDispatch } from '../../../hooks';
import { errorToast, successToast } from '../../../state/notifications';
import { Chip } from '../../../ui/atoms/Chip';
import { Edit, EditProps } from './Edit';

const tAdd = 'Add';
const tTasks = 'Tasks';
const tMilestones = 'Milestones';
const tTaskAddedSuccessMessage = 'New task added!';
const tTaskAddedErrorMessage = 'Failed to add task';
const tTaskCompletedSuccessMessage = 'Task complete!';
const tTaskCompletedErrorMessage = 'Failed to mark task as complete';
const tTaskRemovedSuccessMessage = 'Task removed.';
const tTaskRemovedErrorMessage = 'Failed to remove task';
const tMilestoneAddedSuccessMessage = 'New milestone added!';
const tMilestoneAddedErrorMessage = 'Failed to add milestone';
const tMilestoneCompletedSuccessMessage = 'Milestone complete!';
const tMilestoneCompletedErrorMessage = 'Failed to mark milestone as complete';
const tMilestoneRemovedSuccessMessage = 'Milestone removed.';
const tMilestoneRemovedErrorMessage = 'Failed to remove milestone';

export interface ManageListVariant {
  type: 'milestones' | 'tasks';
  onAction: (item: ManageListProps['items'][0]) => Promise<void>;
  onAdd: (item: Pick<ManageListProps['items'][0], 'description' | 'name'> & { dueDate: Date }) => Promise<void>;
}

export interface ManageListProps extends ListContainerProps {
  items: TaskListProps['items'];
  variant: ManageListVariant;
  refetch: () => void;
  isFacilitator: boolean;
}

const tMilestonesWithCount = (count: number) => `${count} Milestones`;
const tTasksWithCount = (count: number) => `${count} Tasks`;

const useListLogic = (
  isFacilitator: boolean,
  originalItems: TaskListProps['items'],
  variant: ManageListProps['variant'],
  refetch: ManageListProps['refetch']
) => {
  const dispatch = useDispatch();

  const [items, setItems] = useState(originalItems);

  useEffect(() => {
    setItems(originalItems);
  }, [originalItems]);

  const onClick = useCallback(
    (action: 'complete' | 'remove'): TaskListProps['onComplete'] =>
      async (item) => {
        const originalIndex = items.findIndex(({ id }) => id === item.id);
        setItems(items.filter(({ id }) => id !== item.id));
        try {
          if (variant.type === 'milestones') {
            await variant.onAction(item);
            refetch();
            dispatch(
              successToast({
                message: action === 'complete' ? tMilestoneCompletedSuccessMessage : tMilestoneRemovedSuccessMessage,
              })
            );
          }
          if (variant.type === 'tasks') {
            await variant.onAction(item);
            refetch();
            dispatch(
              successToast({
                message: action === 'complete' ? tTaskCompletedSuccessMessage : tTaskRemovedSuccessMessage,
              })
            );
          }
        } catch (exception: unknown) {
          window.console.log({ exception });
          if (variant.type === 'milestones') {
            dispatch(
              errorToast({
                message: action === 'complete' ? tMilestoneCompletedErrorMessage : tMilestoneRemovedErrorMessage,
              })
            );
          }
          if (variant.type === 'tasks') {
            dispatch(
              errorToast({ message: action === 'complete' ? tTaskCompletedErrorMessage : tTaskRemovedErrorMessage })
            );
          }
          const left = items.slice(0, originalIndex);
          const right = items.slice(originalIndex);
          setItems([...left, item, ...right]);
        }
      },
    []
  );

  return {
    props: {
      isFacilitator,
      items,
      onComplete: onClick('complete'),
      onRemove: onClick('remove'),
    },
  };
};

const useEditLogic = (variant: ManageListProps['variant'], refetch: ManageListProps['refetch']) => {
  const dispatch = useDispatch();

  const onSubmit: EditProps['onSubmit'] = useCallback(async ({ date: dueDate, description, name }) => {
    try {
      if (variant.type === 'milestones') {
        await variant.onAdd({ description, dueDate, name });
        refetch();
        dispatch(
          successToast({
            message: tMilestoneAddedSuccessMessage,
          })
        );
      }
      if (variant.type === 'tasks') {
        await variant.onAdd({ description, dueDate, name });
        refetch();
        dispatch(
          successToast({
            message: tTaskAddedSuccessMessage,
          })
        );
      }
    } catch (exception: unknown) {
      window.console.log({ exception });
      if (variant.type === 'milestones') {
        dispatch(
          errorToast({
            message: tMilestoneAddedErrorMessage,
          })
        );
      }
      if (variant.type === 'tasks') {
        dispatch(errorToast({ message: tTaskAddedErrorMessage }));
      }
    }
  }, []);

  return { onSubmit };
};

/**
 * @description Drawer for RoomMilestones, RoomTasks lists
 */
export const ManageList = ({ isFacilitator, items, variant, refetch, ...listContainerProps }: ManageListProps) => {
  const { props: taskListProps } = useListLogic(isFacilitator, items, variant, refetch);
  const { onSubmit } = useEditLogic(variant, refetch);
  const [isEditing, setIsEditing] = useState(false);

  const handleClickAdd = useCallback(() => setIsEditing(true), []);
  const handleCancel = useCallback(() => setIsEditing(false), []);

  const headings = {
    milestones: tMilestones,
    tasks: tTasks,
  };

  const label = variant.type === 'milestones' ? tMilestonesWithCount(items.length) : tTasksWithCount(items.length);

  return (
    <RightSidebar headingText={headings[variant.type]} label={(onclick) => <Chip label={label} onclick={onclick} />}>
      {isEditing && <Edit onCancel={handleCancel} onSubmit={onSubmit} />}
      {!isEditing && isFacilitator && (
        <Button disableRipple fullWidth onClick={handleClickAdd} sx={{ maxWidth: '128px' }}>
          {tAdd}
        </Button>
      )}
      <ListContainer {...listContainerProps}>
        <TaskList {...taskListProps} />
      </ListContainer>
    </RightSidebar>
  );
};
