import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { ExternalLink } from '../../atoms/ExternalLink';
import { ColumnData, DataGrid, RowData } from '../../molecules/DataGrid';
import { CrudListProps } from '../Crud/Manage';
import { Resource } from './types';

const columnData: Record<string, ColumnData<Resource>> = {
  desc: { field: 'description', flex: 2, renderHeader: () => <Typography fontWeight="bold">Description</Typography> },
  name: { field: 'name', flex: 1, renderHeader: () => <Typography fontWeight="bold">Name</Typography> },
  url: {
    field: 'url',
    flex: 1,
    renderCell: (params) => {
      const url = params.row.url;
      return <ExternalLink url={url}>{url}</ExternalLink>;
    },
    renderHeader: () => <Typography fontWeight="bold">Link</Typography>,
  },
};
const columns = [columnData.name, columnData.desc, columnData.url];

const toRowData = (items: Resource[]): RowData<Resource>[] => {
  return items.map((i) => ({ rowId: i.id, ...i }));
};

export const ResourceGrid = ({ items, onSelect }: CrudListProps<Resource>) => {
  const rows = useMemo(() => toRowData(items), [items]);

  return (
    <DataGrid<Resource>
      columns={columns}
      disableColumnSelector
      onRowClick={(params) => onSelect(params.row)}
      rows={rows}
      slotProps={{ empty: { message: 'No Resources' } }}
      slots={{ toolbar: null }}
    />
  );
};
