import { Rsvp as RsvpTable, RsvpProps as RsvpTableProps } from '../../../../cc-ui/components/Tables/Schedule/Rsvp';
import { useSelector } from '../../../../hooks';
import { useUserGetUserRsvpsSearch } from '../../../../services/openapi/generated/CrowdCoursingComponents';

const tLoadingWithEllipses = 'Loading...';
const tOopsWithEllipses = 'Oops...';

interface RsvpComponentProps {
  userId?: string;
}

export const Rsvp = ({ userId }: RsvpComponentProps) => {
  const stateUserId = useSelector((state) => state.user?.id);
  const effectiveUserId = userId ?? stateUserId ?? '';
  const { data, error, isLoading } = useUserGetUserRsvpsSearch({
    pathParams: { userId: effectiveUserId },
  });

  if (isLoading || !data) {
    return <p>{tLoadingWithEllipses}</p>;
  }

  if (error) {
    return <p>{tOopsWithEllipses}</p>;
  }

  // Verify the data belongs to the requested user
  if (data.some((rsvp) => rsvp.userId !== effectiveUserId)) {
    console.error('Received RSVPs for wrong user!', {
      receivedRsvps: data,
      requestedUserId: effectiveUserId,
    });
  }

  const rsvps: RsvpTableProps['rsvps'] = data.map(({ schoolName, eventId, eventName, meetingTimeDto }) => ({
    beginMeetingTime: new Date(meetingTimeDto!.beginMeetingTime!),
    endMeetingTime: new Date(meetingTimeDto!.endMeetingTime!),
    eventId: eventId!,
    eventName: eventName!,
    rsvpUserId: effectiveUserId,
    schoolName: schoolName,
  }));
  const handleClickRow: RsvpTableProps['onClickRow'] = () => {};

  return <RsvpTable onClickRow={handleClickRow} rsvps={rsvps} />;
};
