import { Box, CircularProgress, Typography } from '@mui/material';

interface CheckInStatusProps {
  status: string;
}

export const CheckInStatus = ({ status }: CheckInStatusProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        justifyContent: 'center',
        p: 2,
        textAlign: 'center',
      }}
    >
      <CircularProgress />
      <Typography variant="h6">{status}</Typography>
    </Box>
  );
};
