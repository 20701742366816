import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ChangeEvent, forwardRef, ReactElement, Ref, useState } from 'react';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface RemovalConfirmDialogProps {
  title: string;
  body: string;
  actionText: string;
  onCancel: () => void;
  onConfirm: () => void;
  /**
   * when to show the dialog
   *
   * this component needs to be in the DOM in order for transitions to work
   */
  isOpen: boolean;
}

const tCancel = 'Cancel';
const tConfirm = 'Confirm';
const tInstructions = 'Enter "confirm" in the text field below if you wish to continue with this action.';

const RemovalConfirmDialog = ({ title, body, actionText, onCancel, onConfirm, isOpen }: RemovalConfirmDialogProps) => {
  const [value, setValue] = useState<string>('');

  const isDisabled = !value || value.toLowerCase() !== tConfirm.toLowerCase();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClickOk = () => onConfirm();
  const handleClickCancel = () => onCancel();

  return (
    <Dialog
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="alert-dialog-slide-title"
      onClose={handleClickCancel}
      open={isOpen}
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <p>{body}</p>
          {tInstructions.replace('$confirm', tConfirm)}
        </DialogContentText>
        <TextField
          data-testid="remove-confirm-textfield"
          onChange={handleChange}
          placeholder={tConfirm}
          sx={{ margin: '2rem 0' }}
          value={value}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid="remove-confirm-cancel-button" onClick={handleClickCancel}>
          {tCancel}
        </Button>
        <Button color="error" data-testid="remove-confirm-ok-button" disabled={isDisabled} onClick={handleClickOk}>
          {actionText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemovalConfirmDialog;
