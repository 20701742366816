import CloseIcon from '@mui/icons-material/Close';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ReactNode } from 'react';

export interface DialogProps {
  actions: ReactNode;
  dialogType: 'custom' | 'confirm' | 'info';
  onDialogClose: () => void;
  open: boolean;
  title: string;
}

export const Dialog = (props: DialogProps) => {
  const { actions, dialogType, onDialogClose, open, title } = props;

  return (
    <MuiDialog aria-labelledby="dialog-title" fullWidth maxWidth="md" onClose={onDialogClose} open={open}>
      <DialogTitle id="dialog-title" sx={{ m: 0, p: 2 }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onDialogClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{dialogType === 'custom' && actions}</DialogContent>
      {dialogType !== 'custom' && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
};

export default Dialog;
