import { Box, Typography } from '@mui/material';

import { QRCodeImage } from './QRCodeImage';

interface QRCodeDisplayProps {
  title: string;
  subtitle: string;
  qrCodeSrc: string;
  instructions: string[];
  adminNote?: string;
  error?: string | null;
}

export const QRCodeDisplay = ({ title, subtitle, qrCodeSrc, instructions, adminNote, error }: QRCodeDisplayProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        justifyContent: 'center',
        p: 2,
        textAlign: 'center',
      }}
    >
      <Typography variant="h4">{title}</Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
      {error ? <Typography color="error">{error}</Typography> : <QRCodeImage src={qrCodeSrc} />}
      <Box sx={{ mt: 2 }}>
        {instructions.map((instruction, index) => (
          <Typography key={index} variant="body2">
            {instruction}
          </Typography>
        ))}
      </Box>
      {adminNote && (
        <Typography color="text.secondary" variant="body2">
          {adminNote}
        </Typography>
      )}
    </Box>
  );
};
