import { type UserHistoryDto } from '@crowdcoursing/api/openapi';
import { Box, type BoxProps, Card, CardContent, Grid, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import { formatMinuteStat, formatToMoney } from '~ui/pages/AdminPage/utils';

const tThisWeek = 'This Week';
const tThisMonth = 'This Month';
const tThisYear = 'This Year';
const tAllTime = 'All Time';
const tVolunteerStatistics = 'Volunteer Stats';
const tNoStats = 'No volunteer stats';

function Container(props: BoxProps) {
  return (
    <Box {...props}>
      <Box>
        <Typography gutterBottom variant="h5">
          {tVolunteerStatistics}
        </Typography>
      </Box>
      {props.children}
    </Box>
  );
}

export type UserHistoryStatsBaseProps = { volunteerStats: NonNullable<UserHistoryDto['volunteerStats']> };
export type UserHistoryStatsSlotProps = { slotProps?: { container?: BoxProps } };
export type UserHistoryStatsProps = UserHistoryStatsBaseProps & UserHistoryStatsSlotProps;

export const UserHistoryVolunteerStats = ({ volunteerStats, slotProps }: UserHistoryStatsProps) => {
  const stats = useMemo(
    function () {
      return [
        {
          label: tThisWeek,
          value: `${formatMinuteStat(volunteerStats.minutesThisWeek)} (${formatToMoney(
            volunteerStats.economicImpactWeek ?? 0
          )})`,
        },
        {
          label: tThisMonth,
          value: `${formatMinuteStat(volunteerStats.minutesThisMonth)} (${formatToMoney(
            volunteerStats.economicImpactMonth ?? 0
          )})`,
        },
        {
          label: tThisYear,
          value: `${formatMinuteStat(volunteerStats.minutesThisYear)} (${formatToMoney(
            volunteerStats.economicImpactYTD ?? 0
          )})`,
        },
        {
          label: tAllTime,
          value: `${formatMinuteStat(volunteerStats.minutesAllTime)} (${formatToMoney(
            volunteerStats.economicImpactAllTime ?? 0
          )})`,
        },
      ];
    },
    [volunteerStats]
  );

  if (isEmpty(volunteerStats)) {
    return (
      <Container {...slotProps?.container}>
        <Typography sx={{ fontStyle: 'italic' }}>{tNoStats}</Typography>
      </Container>
    );
  }

  return (
    <Container {...slotProps?.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxHeight: '350px',
          overflowY: 'auto',
          paddingBottom: 1,
          paddingTop: 1,
          px: 1,
        }}
      >
        <Card sx={{ boxShadow: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              {stats.map(function ({ label, value }, index) {
                return (
                  <Grid item key={`${label}-${index}`} xs={6}>
                    <Typography color="text.secondary" variant="body2">
                      {label}
                    </Typography>
                    <Typography>{value}</Typography>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};
