export type GraphQLResponseError<T extends object = object> = T & {
  extensions?: {
    code?: string;
  };
};

export type GraphQLResponse<TData, TError extends GraphQLResponseError = GraphQLResponseError> =
  | { data: TData; errors?: undefined }
  | { data?: undefined; errors: GraphQLResponseError<TError>[] };

type BaseConfig = {
  fetcherOptions: {
    config: {
      baseUrl: string;
      onResponse?: (response: GraphQLResponse<unknown>) => Promise<void>;
    };
    headers?: HeadersInit;
  };
};

class ApiConfigurationManager<T extends BaseConfig = BaseConfig> {
  // @ts-expect-error I suppose it could be instantiated with a different object. Let's just not do that
  #config: T = { fetcherOptions: { config: { baseUrl: '' } } };

  set config(config: T) {
    this.#config = config;
  }

  get config() {
    return this.#config;
  }
}

const apiConfiguration = new ApiConfigurationManager();
export function configureGraphQL(config: BaseConfig) {
  apiConfiguration.config = config;
}

export const useFetch = <Data, Variables>(
  query: string,
  requestHeaders?: RequestInit['headers'],
): ((variables?: Variables) => Promise<Data>) => {
  return async (variables?: Variables) => {
    const response = await fetch(`${resolveUrl(apiConfiguration.config.fetcherOptions.config.baseUrl)}/graphql`, {
      body: JSON.stringify({ query, variables }),
      headers: {
        'Content-Type': 'application/json',
        ...apiConfiguration.config.fetcherOptions.headers,
        ...requestHeaders,
      },
      method: 'POST',
    });

    const result = (await response.json()) as GraphQLResponse<Data>;
    await apiConfiguration.config.fetcherOptions.config.onResponse?.(result);
    if (result.errors) {
      throw new Error(`GraphQL response has errors. ${JSON.stringify(result.errors, null, 2)}`);
    }

    return result.data;
  };
};

/**
 * makes a valid absolute or relative URL, splicing in query/path params.
 *
 * prevents building urls with double slashes like http://BASEURL//api/v1/...
 */
export const resolveUrl = (url: string) => {
  return url.endsWith('/') ? url.slice(0, url.length - 1) : url;
};
