import { QRCodeDisplay } from '../../molecules/CheckIn/QRCodeDisplay';

interface QRCodeTemplateProps {
  title: string;
  subtitle: string;
  qrCodeSrc: string;
  instructions: string[];
  adminNote?: string;
  error?: string | null;
}

export const QRCodeTemplate = ({ title, subtitle, qrCodeSrc, instructions, adminNote, error }: QRCodeTemplateProps) => {
  return (
    <QRCodeDisplay
      adminNote={adminNote}
      error={error}
      instructions={instructions}
      qrCodeSrc={qrCodeSrc}
      subtitle={subtitle}
      title={title}
    />
  );
};
