import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch } from '../../../hooks';
import { useCrowdCoursingContext } from '../../../services/openapi/generated/CrowdCoursingContext';
import { crowdCoursingFetch } from '../../../services/openapi/generated/CrowdCoursingFetcher';
import { Route } from '../../../services/router/Route';
import { errorToast, successToast } from '../../../state/notifications';
import { CheckInTemplate } from '../../templates/CheckIn/CheckInTemplate';

const tCheckingIn = 'Checking in...';
const tCheckInSuccess = 'Successfully checked in!';
const tCheckInError = 'Failed to check in. Please try again.';

// Custom hook for bubble check-in
const useBubbleCheckIn = (bubbleId?: string) => {
  const { fetcherOptions } = useCrowdCoursingContext();

  return useMutation({
    mutationFn: () =>
      crowdCoursingFetch({
        method: 'post',
        url: `/api/v1/Bubbles/${bubbleId}/check-in`,
        ...fetcherOptions,
      }),
    onError: (_error) => {
      errorToast({ message: tCheckInError });
    },
  });
};

export const CheckInPage = () => {
  const { bubbleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate: checkIn } = useBubbleCheckIn(bubbleId);

  useEffect(() => {
    if (bubbleId) {
      checkIn(undefined, {
        onSuccess: () => {
          dispatch(successToast({ message: tCheckInSuccess }));
          navigate(Route.home());
        },
      });
    }
  }, [bubbleId, checkIn, dispatch, navigate]);

  return <CheckInTemplate status={tCheckingIn} />;
};
