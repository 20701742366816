import { Button } from '@mui/material';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface NavLinkWrapperProps {
  to: string;
  children: ReactNode;
}

export const NavLinkWrapper = ({ to, children }: NavLinkWrapperProps) => {
  return (
    <Button
      component={RouterLink}
      sx={{
        borderRadius: '4px',
        color: 'inherit',
        justifyContent: 'flex-start',
        padding: {
          md: '1rem',
          sm: '0.75rem',
          xs: '0.5rem',
        },
        textAlign: 'left',
        textTransform: 'none',
        width: '100%',
      }}
      to={to}
      variant="text"
    >
      {children}
    </Button>
  );
};
