import { Box, Grid, styled, Typography } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';

import { search } from '../../actions/search';
import { useFeature } from '../../hooks';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useDispatch } from '../../hooks/useTypedRedux';
import { SchoolDistrictService } from '../../services/ApiService';
import { useSearchOptionsQuery } from '../../services/graphql/generated/react-query';
import {
  DateRange,
  DateRangeValue,
  FIELD_NAME_DATE_RANGE,
  Form,
  FormProps,
  getParsedSelected,
  getParsedSelectedMultiple,
  ResetButton,
  SelectValueMultiple,
  SelectValueSingle,
  SubmitButton,
  TextFieldValue,
} from '../../ui/atoms/Form';
import {
  BubbleSelect,
  DistrictSelect,
  FIELD_NAME_BUBBLE_SELECT,
  FIELD_NAME_DISTRICT_SELECT,
  FIELD_NAME_MEETING_DAYS_SELECT,
  FIELD_NAME_ROOM_OR_EVENT_NAME,
  FIELD_NAME_ZIPCODE,
  MeetingDaysSelect,
  RoomOrEventName,
  Zipcode,
} from '../../ui/molecules/Form';
import { SearchGrid, SearchResult } from './PodGrid';

const SEARCH_FORM_LOCAL_STORAGE_KEY = 'CrowdCoursing-SearchForm';
const SEARCH_FORM_QUERY_PARAM_KEY = 'usePreviousValues';

const tTitle = 'Search';
const tSubtitle = 'Rooms and Events';
const tClear = 'Clear';
const tSearch = 'Search';

const FieldContainer = styled(Box)(({ theme }) => {
  return {
    display: 'grid',
    gap: '2rem',
    gridAutoFlow: 'column',
    [theme.breakpoints.down('md')]: {
      gridAutoFlow: 'row',
    },
  };
});

const FieldsContainer = styled(Box)(({ theme }) => {
  return {
    display: 'grid',
    gap: '3rem',
    gridAutoFlow: 'row',
    [theme.breakpoints.down('md')]: {
      gap: '2rem',
    },
  };
});

interface FormValues {
  [FIELD_NAME_BUBBLE_SELECT]: SelectValueSingle;
  [FIELD_NAME_DATE_RANGE]: DateRangeValue;
  [FIELD_NAME_DISTRICT_SELECT]: SelectValueSingle;
  [FIELD_NAME_MEETING_DAYS_SELECT]: SelectValueMultiple;
  [FIELD_NAME_ROOM_OR_EVENT_NAME]: TextFieldValue;
  [FIELD_NAME_ZIPCODE]: TextFieldValue;
}

interface SearchProps {
  onSubmit: FormProps<FormValues>['onSubmit'];
  onClear: () => void;
}

const SearchForm = ({ onSubmit, onClear }: SearchProps) => {
  const [schools, setSchools] = useState<Awaited<ReturnType<typeof SchoolDistrictService.schools1>>>([]);
  const { data: searchOptionsData } = useSearchOptionsQuery();

  const fetchSchools = useCallback(async (id: string) => {
    setSchools(await SchoolDistrictService.schools1({ schoolDistrictId: id }));
  }, []);

  const isDistrict = useFeature('districtMode');

  return (
    <Grid container direction="row">
      <Grid id="search-form" item xs={12}>
        <Form onSubmit={onSubmit}>
          <FormSpy
            render={({ form }) => {
              const [queryParams] = useSearchParams();
              const { getLocalStorageItem } = useLocalStorage<FormValues>(SEARCH_FORM_LOCAL_STORAGE_KEY);

              const shouldUsePreviousValues = queryParams.get(SEARCH_FORM_QUERY_PARAM_KEY);

              useEffect(() => {
                if (shouldUsePreviousValues) {
                  const previousValues = getLocalStorageItem();
                  if (previousValues) {
                    form.initialize({
                      ...previousValues,
                      [FIELD_NAME_DATE_RANGE]: previousValues[FIELD_NAME_DATE_RANGE]?.map((date) => dayjs(date)),
                    });
                    form.submit();
                  }
                }
              }, [shouldUsePreviousValues]);

              return null;
            }}
          />
          <FieldsContainer>
            {isDistrict && (
              <>
                <FieldContainer gridAutoColumns="1fr 1fr">
                  <Grid id="search-form-school-district">
                    <DistrictSelect
                      selectItems={(searchOptionsData?.activeSchoolDistricts ?? []).map(
                        ({ schoolDistrictId: id, name: value }) => ({
                          id,
                          value,
                        })
                      )}
                    />
                  </Grid>

                  <FormSpy<FormValues>
                    render={({ values: { districtSelect } }) => {
                      useEffect(() => {
                        if (districtSelect) {
                          fetchSchools(JSON.parse(districtSelect).id);
                        }
                      }, [districtSelect]);

                      return (
                        <BubbleSelect
                          selectItems={schools.map(({ bubbleId: id, schoolName: value }) => ({
                            id: id!,
                            value: value!,
                          }))}
                          selectProps={{ disabled: schools.length === 0 }}
                        />
                      );
                    }}
                  />
                </FieldContainer>
              </>
            )}

            <FieldContainer gridAutoColumns="2fr 1fr" id="search-form-room-name">
              <RoomOrEventName />
              <Grid id="search-form-zip-code">
                <Zipcode required={false} />
              </Grid>
            </FieldContainer>

            <FieldContainer gridAutoColumns="1fr 1fr" id="search-form-meeting-days">
              <MeetingDaysSelect />
              <DateRange dateRangePickerProps={{ disablePast: true }} />
            </FieldContainer>
          </FieldsContainer>
          <Box display="grid" gap="1rem" gridAutoFlow="column" justifyContent="end" marginTop="3rem" width="100%">
            <ResetButton data-testid="clear-button" id="search-form-clear-button" onClick={onClear}>
              {tClear}
            </ResetButton>
            <SubmitButton data-testid="search-button" id="search-form-search-button">
              {tSearch}
            </SubmitButton>
          </Box>
        </Form>
      </Grid>
    </Grid>
  );
};

const SearchContainer = () => {
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setQueryParams] = useSearchParams();
  const { setLocalStorageItem } = useLocalStorage<FormValues>(SEARCH_FORM_LOCAL_STORAGE_KEY);

  const handleSubmit = useCallback<SearchProps['onSubmit']>(
    async (values) => {
      setLocalStorageItem(values);
      setQueryParams({ [SEARCH_FORM_QUERY_PARAM_KEY]: 'true' });

      const { bubbleSelect, dateRange, districtSelect, meetingDaysSelect, roomOrEventName: roomName, zipCode } = values;

      let endDate: string | undefined = undefined;
      let startDate: string | undefined = undefined;

      if (dateRange) {
        const [start, end] = dateRange;
        startDate = (start && start.format('MM/DD/YYYY')) ?? undefined;
        endDate = (end && end.format('MM/DD/YYYY')) ?? undefined;
      }

      const opts = {
        endDate,
        meetingDays: meetingDaysSelect && getParsedSelectedMultiple(meetingDaysSelect).map(({ value }) => value),
        roomName,
        schoolBubbleId: bubbleSelect && String(getParsedSelected(bubbleSelect).id),
        schoolDistrictBubbleId: districtSelect && String(getParsedSelected(districtSelect).id),
        startDate,
        zipCode,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // redux-toolkit typing is impossible, switch this react-query
      const result = await dispatch(search(opts)).then(unwrapResult);

      setSearchResult(result);
    },
    [dispatch, setSearchResult]
  );

  const handleClear = useCallback(() => {
    setSearchResult([]);
  }, [setSearchResult]);

  return (
    <>
      <Box display="grid" gap="4rem" gridAutoFlow="row">
        <Box display="grid" gap="3rem" gridAutoFlow="row">
          <Box display="grid" gridAutoFlow="row">
            <Typography variant="h4">{tTitle}</Typography>
            <Typography variant="h5">{tSubtitle}</Typography>
          </Box>
          <SearchForm onClear={handleClear} onSubmit={handleSubmit} />
        </Box>
        <SearchGrid searchResults={searchResult} />
      </Box>
    </>
  );
};

export default SearchContainer;
