import { Alert, Box, Theme, Typography, useMediaQuery } from '@mui/material';

import { VolunteeringRecordDto } from '~services/openapi/generated/CrowdCoursingSchemas';
import { Volunteers } from '~ui/organisms/DataGrid/Volunteers/Volunteers';

export type UserHistoryVolunteersProps = {
  data: VolunteeringRecordDto[];
  userId: string;
};

const tVolunteeringRecords = 'Volunteering Records';
const tMobileWarning = 'Turn your phone sideways to best view the table';

export const UserHistoryVolunteerRecords = ({ data, userId }: UserHistoryVolunteersProps) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const status = data.length != null && data.length != undefined ? 'success' : 'pending';
  return (
    <Box sx={{ display: 'grid' }}>
      <Box>
        <Typography gutterBottom variant="h5">
          {tVolunteeringRecords}
        </Typography>
        {isMobile && <Alert color="warning">{tMobileWarning}</Alert>}
      </Box>
      <Box sx={{ padding: 1 }}>
        <Volunteers data={data} status={status} userId={userId} />
      </Box>
    </Box>
  );
};
