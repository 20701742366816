import { Alert, Grid, GridProps, Tab, Tabs, Theme, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';

import { FormattedData, useMyScheduleOwner } from '../../../../services/graphql/queries/mySchedule';
import { RoomContainer } from './Room';

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const DAYS_SHORT = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
const tMyFacilitatorSchedule = 'Upcoming Hosted Events';
const tMyFacilitatorScheduleSubtext = 'These are your upcoming events that you are hosting or facilitating';
const tNoEventsThisWeek = 'You do not have any events this week.';

const getOrderedDays = (days: string[]) => {
  const todaysIndex = dayjs().day();
  return [...days.slice(todaysIndex), ...days.slice(0, todaysIndex)];
};

interface TabPanelBaseProps {
  selectedTabId: string;
}

type TabPanelProps = TabPanelBaseProps & GridProps & React.HTMLAttributes<HTMLDivElement>;

const TabPanel = ({ children, selectedTabId, ...props }: TabPanelProps) => (
  <Grid
    role="tabpanel"
    sx={{
      padding: '2rem 0',
      position: 'absolute',
      visibility: props.id === selectedTabId ? 'visible' : 'hidden',
      width: '100%',
    }}
    {...props}
  >
    {children}
  </Grid>
);

export interface ThisWeekProps {
  roomData: FormattedData[];
}

export const ThisWeek = ({ roomData }: ThisWeekProps) => {
  return (
    <Grid container direction="row" width="100%">
      {roomData
        .filter((data) => data.thisWeek.length > 0)
        .map((data, index) => (
          <Grid item key={`${index}-${data.id}`} width="100%">
            <RoomContainer description={data.description} id={data.id} name={data.name} sessions={data.thisWeek} />
          </Grid>
        ))}
    </Grid>
  );
};

export const ThisWeekContainer = () => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const days = isMobile ? DAYS_SHORT : DAYS;
  const orderedDays = getOrderedDays(days);
  const [selectedTabId, setSelectedTabId] = useState(orderedDays[0]);
  const { data, isLoading, error } = useMyScheduleOwner();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Oops...</p>;
  }

  const handleChangeTab = (_event: React.SyntheticEvent, tabId: string) => setSelectedTabId(tabId);

  const daysToData = days.map((day) => ({ [day]: [] as FormattedData[] })).reduce((a, b) => ({ ...a, ...b }));
  let anyHostedEvents = false;
  data?.myRooms?.forEach((room) => {
    days.forEach((day, index) => {
      const matches = room.thisWeek.filter(({ beginMeetingTime }) => dayjs(beginMeetingTime).day() === index);
      if (matches.length > 0) {
        daysToData[day].push({ ...room, thisWeek: matches });
        anyHostedEvents = true;
      }
    });
  });

  return (
    <>
      <Typography variant="h5">{tMyFacilitatorSchedule}</Typography>
      {anyHostedEvents && <Alert severity="info">{tMyFacilitatorScheduleSubtext}</Alert>}
      {!anyHostedEvents && <Alert severity="warning">{tNoEventsThisWeek}</Alert>}
      <Tabs aria-label="foo" onChange={handleChangeTab} scrollButtons="auto" value={selectedTabId} variant="scrollable">
        {orderedDays.map((day) => (
          <Tab aria-controls={day} id={day} key={day} label={day} value={day} />
        ))}
      </Tabs>
      <Grid sx={{ minHeight: '200px', position: 'relative' }}>
        {orderedDays.map((day) => (
          <TabPanel aria-labelledby={day} id={day} key={day} selectedTabId={selectedTabId}>
            {daysToData[day].length === 0 ? (
              <Grid container direction="row" width="100%">
                <Grid item width="100%">
                  <Typography>Nothing coming up on {day}</Typography>
                </Grid>
              </Grid>
            ) : (
              <ThisWeek roomData={daysToData[day]} />
            )}
          </TabPanel>
        ))}
      </Grid>
    </>
  );
};
