import { UserHistoryDto } from '@crowdcoursing/api/openapi';
import { Avatar, Box, type BoxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { makeEventAvatarUrl } from '../../../utilities/utils';

const tHost = 'Host';
const tEvents = 'My Events';
const tNoEvents = 'No events';

function Container(props: BoxProps) {
  return (
    <Box {...props}>
      <Box>
        <Typography gutterBottom variant="h5">
          {tEvents}
        </Typography>
      </Box>
      {props.children}
    </Box>
  );
}

export type UserHistoryEventsBaseProps = { events: NonNullable<UserHistoryDto['events']> };
export type UserHistoryEventsSlotProps = { slotProps?: { container?: BoxProps } };
export type UserHistoryEventsProps = UserHistoryEventsBaseProps & UserHistoryEventsSlotProps;

export const UserHistoryEvents = ({ events, slotProps }: UserHistoryEventsProps) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const avatarIconSize = isMobile ? '3em' : '3.75em';

  if (events.length === 0) {
    return (
      <Container {...slotProps?.container}>
        <Typography sx={{ fontStyle: 'italic' }}>{tNoEvents}</Typography>
      </Container>
    );
  }

  return (
    <Container {...slotProps?.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxHeight: '500px',
          overflowY: 'auto',
          padding: 1,
        }}
      >
        {events.map((event) => (
          <Link
            key={event.id}
            style={{ color: 'inherit', textDecoration: 'none' }}
            to={Route.eventView({ eventId: event.id ?? 'NO_EVENT_ID' })}
          >
            <Box
              padding={1}
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                borderRadius: 2,
                boxShadow: 2,
                display: 'grid',
                gap: 2,
                gridAutoColumns: 'auto 1fr',
                gridAutoFlow: 'column',
                marginBottom: '0.25em',
                marginTop: '0.25em',
                p: 2,
              }}
            >
              <Avatar src={makeEventAvatarUrl(event.id ?? '')} sx={{ height: avatarIconSize, width: avatarIconSize }} />
              <Box>
                <Typography sx={{ fontWeight: 'medium' }} variant="h6">
                  {event.name}
                </Typography>
                <Typography color="text.secondary" variant="body2">
                  {tHost}: {event.ownerFirstName} {event.ownerLastName}
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    mt: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="body2"
                >
                  {event.description}
                </Typography>
              </Box>
            </Box>
          </Link>
        ))}
      </Box>
    </Container>
  );
};
