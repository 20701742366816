import { Business as BusinessIcon } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useState } from 'react';

import { useNavigationBarQuery } from '../../../services/graphql/generated/react-query';
import { Route } from '../../../services/router/Route';
import { useOrganizations } from '../../hooks/useOrganizations';
import { CreateOrganizationDialog } from '../../organisms/Organizations/CreateOrganizationDialog';
import { OrganizationItemWithAvatar } from '../../organisms/Organizations/OrganizationItemWithAvatar';
import { Organization } from '../../organisms/Organizations/types';
import { OrganizationsTemplate } from '../../templates/OrganizationsTemplate';
import { NavLinkWrapper } from '../../templates/OrganizationsTemplate/NavLinkWrapper';
import { tAddOrganization, tMyOrganizations } from '../../translations/organizations';

export const OrganizationsPage = () => {
  const [isOrganizationDialogOpen, setIsOrganizationDialogOpen] = useState(false);
  const { data } = useNavigationBarQuery();
  const isSiteAdmin = data?.me.isSiteAdmin ?? false;

  const { organizations, isLoading: isOrganizationsLoading, error: organizationsError } = useOrganizations();

  const handleOpenOrganizationDialog = () => setIsOrganizationDialogOpen(true);
  const handleCloseOrganizationDialog = () => setIsOrganizationDialogOpen(false);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{ alignItems: 'center', display: 'flex', gap: { sm: 2, xs: 1 }, justifyContent: 'space-between', mb: 2 }}
        >
          <Box sx={{ alignItems: 'center', display: 'flex', gap: { sm: 2, xs: 1 } }}>
            <BusinessIcon
              color="primary"
              sx={{
                fontSize: {
                  md: 36,
                  sm: 28,
                  xs: 24,
                },
              }}
            />
            <Typography
              color="primary.main"
              sx={{
                fontSize: {
                  md: '2rem',
                  sm: '1.5rem',
                  xs: '1.25rem',
                },
                fontWeight: 500,
              }}
              variant="h4"
            >
              {tMyOrganizations}
            </Typography>
          </Box>
          {isSiteAdmin && (
            <Button
              color="primary"
              onClick={handleOpenOrganizationDialog}
              size="small"
              sx={{
                fontSize: {
                  md: '0.875rem',
                  sm: '0.8125rem',
                  xs: '0.75rem',
                },
                px: { sm: 2, xs: 1 },
                py: { md: 1, sm: 0.75, xs: 0.5 },
              }}
              variant="contained"
            >
              {tAddOrganization}
            </Button>
          )}
        </Box>
        <Divider sx={{ mb: 3 }} />
      </Box>

      <OrganizationsTemplate
        error={organizationsError}
        isLoading={isOrganizationsLoading}
        isSiteAdmin={isSiteAdmin}
        organizations={organizations}
        renderOrganizationItem={(organization: Organization) => (
          <NavLinkWrapper to={Route.organization({ organizationId: organization.id ?? '' })}>
            <OrganizationItemWithAvatar isSiteAdmin={isSiteAdmin} organization={organization} />
          </NavLinkWrapper>
        )}
        showHeader={false}
      />

      {isOrganizationDialogOpen && <CreateOrganizationDialog onClose={handleCloseOrganizationDialog} />}
    </Box>
  );
};
