import { Avatar, Badge, Grid, styled, Typography } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';

import tProfileIcon from '../../../components/Icons/Update_Icon.png';

export interface PictureUploadProps {
  onChange: (f: File) => void;
  imageUrl?: string;
  name: string;
}

const tHelp = 'Click to upload a new image';
const INPUT_ID = 'CrowdCoursing-picture-input';
const HiddenInput = styled('input')({ display: 'none' });

/**
 * `PictureUpload` covers room and profile picture changing.
 */
export const PictureUpload = ({ imageUrl, onChange, name }: PictureUploadProps) => {
  useEffect(() => {
    const img = new Image();
    if (imageUrl) {
      img.src = imageUrl;
    }
  }, [imageUrl]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length == 1) {
      onChange(event.target.files[0]);
    }
  };

  return (
    <label data-testid="PictureUpload" htmlFor={INPUT_ID} title={tHelp}>
      <HiddenInput accept="image/*" id={INPUT_ID} onChange={handleChange} type="file" />
      <Grid item>
        <Typography textAlign="center" variant="h3">
          {name}
        </Typography>
      </Grid>
      <Grid id="profile-picture" item>
        <Badge
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          badgeContent={
            <Avatar
              src={tProfileIcon}
              sx={{ cursor: 'pointer', height: (theme) => theme.spacing(6), width: (theme) => theme.spacing(6) }}
            />
          }
          overlap="circular"
        >
          <Avatar
            src={imageUrl}
            sx={{ cursor: 'pointer', height: (theme) => theme.spacing(25), width: (theme) => theme.spacing(25) }}
          />
        </Badge>
      </Grid>
    </label>
  );
};
