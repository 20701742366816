import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { useDispatch, useIsLoading } from '../../hooks';
import { PaymentService } from '../../services/ApiService';
import { pageDialogOpen } from '../../state/app';

// todo: https://mui.com/guides/migration-v4/#migrate-from-jss
const tMakePayment = 'Pay';
const tSetupPayments = 'Setup Payments';

/** local hook to fetch payment account data  */
const useCanAcceptPayments = (userId: string) => {
  const [canAcceptPayments, setCanAcceptPayments] = useState(false);

  useEffect(() => {
    const f = async () => {
      try {
        const result = await PaymentService.paymentAccount1({ userId });
        setCanAcceptPayments((result.accountCreated && result.payoutsEnabled) ?? false);
      } catch {
        setCanAcceptPayments(false);
      }
    };
    f();
  }, [userId, setCanAcceptPayments]);

  return canAcceptPayments;
};

/**
 * informational message to facilitators that they are not setup for payments
 */
const FacilitatorPaymentNotConfigured = () => {
  // TODO: make translatable
  return (
    <Link style={{ textDecoration: 'none' }} to={Route.profile()}>
      <Button color="primary" data-testid="FacilitatorPaymentNotConfigured">
        {tSetupPayments}
      </Button>
    </Link>
  );
};

/** button for uses to start the payment process */
const PayActionButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(pageDialogOpen({ contentProps: {}, contentType: 'pod-payment-wizard' }));
  };

  return (
    <Button color="primary" data-testid="PayActionButton" onClick={handleClick}>
      {tMakePayment}
    </Button>
  );
};

interface PayRoomProps {
  ownerId: string;
  isOwner: boolean;
}

/**
 * display payment-related UI for a room, depending on facilitator and payment
 * configuration.
 *
 */
export const PodPaymentAction = ({ ownerId, isOwner }: PayRoomProps) => {
  const isLoading = useIsLoading();
  const canAcceptPayments = useCanAcceptPayments(ownerId);
  if (isLoading) {
    return <>Loading...</>;
  } else if (isOwner && !canAcceptPayments) {
    return <FacilitatorPaymentNotConfigured />;
  } else if (canAcceptPayments) {
    return <PayActionButton />;
  } else {
    return null;
  }
};
