import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useBubbleGetBubble } from '../../../services/openapi/generated/CrowdCoursingComponents';
import { useCrowdCoursingContext } from '../../../services/openapi/generated/CrowdCoursingContext';
import { crowdCoursingFetch } from '../../../services/openapi/generated/CrowdCoursingFetcher';
import { QRCodeTemplate } from '../../templates/CheckIn/QRCodeTemplate';

const tLoading = 'Loading QR code...';
const tErrorUnavailable = 'QR code not available';
const tErrorLoadingBubble = 'Error loading data';
const tAdminOnlyNote = 'Note: Only administrators can access this QR code.';
const tBubbleDefault = 'Join';
const tScanQRCode = 'Scan the code above to join and check in';
const tInstructions = [
  '• If not logged in, they will be directed to log in first',
  '• After logging in, they will be automatically checked in',
  '• They will receive a confirmation notification',
];

// Custom hook for getting the bubble check-in QR code
const useBubbleCheckInQrCode = (bubbleId?: string) => {
  const { fetcherOptions } = useCrowdCoursingContext();

  return useQuery({
    enabled: !!bubbleId,
    queryFn: () =>
      crowdCoursingFetch({
        method: 'get',
        url: `/api/v1/Bubbles/${bubbleId}/check-in.svg`,
        ...fetcherOptions,
      }),
    queryKey: ['bubble-check-in-qr', bubbleId],
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

export const QRCodePage = () => {
  const { bubbleId } = useParams();
  const [error, setError] = useState<string | null>(null);
  const [svgData, setSvgData] = useState<string | undefined>(undefined);

  // Fetch bubble data to display the name
  const {
    data: bubbleData,
    error: bubbleError,
    isLoading: isBubbleLoading,
  } = useBubbleGetBubble({ pathParams: { bubbleId: bubbleId! } }, { enabled: !!bubbleId });

  // Get the QR code
  const { data: qrCodeData, error: qrCodeError, isLoading: isQrCodeLoading } = useBubbleCheckInQrCode(bubbleId);

  // Process QR code data when it's available
  useEffect(() => {
    if (qrCodeData) {
      const svg = qrCodeData as string;
      const blob = new Blob([svg], { type: 'image/svg+xml' });
      setSvgData(URL.createObjectURL(blob));
    }
  }, [qrCodeData]);

  // Handle errors
  useEffect(() => {
    if (bubbleError) {
      setError(tErrorLoadingBubble);
    } else if (qrCodeError) {
      setError(tErrorUnavailable);
    } else if (!svgData && !isQrCodeLoading) {
      setError(tErrorUnavailable);
    } else if (svgData) {
      setError(null);
    }
  }, [bubbleData, bubbleError, qrCodeError, svgData, isQrCodeLoading]);

  const isLoading = isBubbleLoading || isQrCodeLoading;

  if (isLoading) {
    return (
      <QRCodeTemplate
        adminNote={tAdminOnlyNote}
        error={tLoading}
        instructions={tInstructions}
        qrCodeSrc=""
        subtitle={tScanQRCode}
        title={tBubbleDefault}
      />
    );
  }

  return (
    <QRCodeTemplate
      adminNote={tAdminOnlyNote}
      error={error}
      instructions={tInstructions}
      qrCodeSrc={svgData || ''}
      subtitle={tScanQRCode}
      title={bubbleData?.name || tBubbleDefault}
    />
  );
};
