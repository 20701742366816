import { Box, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { OrganizationItem } from '../../organisms/Organizations/OrganizationItem';
import { Organization } from '../../organisms/Organizations/types';

export interface OrganizationsTemplateProps {
  organizations: Organization[];
  isLoading: boolean;
  error: unknown;
  showHeader?: boolean;
  isSiteAdmin?: boolean;
  renderOrganizationItem?: (organization: Organization) => React.ReactNode;
}

export const OrganizationsTemplate = ({
  organizations,
  isLoading,
  error,
  showHeader = true,
  isSiteAdmin = false,
  renderOrganizationItem,
}: OrganizationsTemplateProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOrganizations = useMemo(() => {
    if (!organizations) return [];
    return organizations.filter((org) => org.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [organizations, searchTerm]);

  return (
    <Box>
      {showHeader && (
        <Box sx={{ mb: 3 }}>
          <Typography gutterBottom variant="h5">
            Organizations
          </Typography>
          <TextField
            fullWidth
            label="Search Organizations"
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            value={searchTerm}
          />
        </Box>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">Error loading organizations</Typography>
      ) : filteredOrganizations.length === 0 ? (
        <Typography>No organizations found</Typography>
      ) : (
        <Grid container spacing={2}>
          {filteredOrganizations.map((organization) => (
            <Grid item key={organization.id} xs={12}>
              {renderOrganizationItem ? (
                renderOrganizationItem(organization)
              ) : (
                <OrganizationItem isSiteAdmin={isSiteAdmin} organization={organization} />
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
