import { Link, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

import { Table } from '../../cc-ui/components';
import { useEventUpcomingRsvpsQuery } from '../../services/graphql/generated/react-query';
import { generateMeetingTime } from '../../utilities/utils';

const tTitle = 'Upcoming Attendees';
const tUnknownUser = 'Unknown User';

const HEADERS = ['Name', 'Contact', 'Event Time'];

const getFixedDateRange = () => {
  return {
    end: dayjs().startOf('day').add(1, 'year'),
    start: dayjs().startOf('day'),
  };
};

const getUserName = (first?: string | null, last?: string | null) => {
  if (first && last) {
    return `${first} ${last}`;
  }

  if (first) {
    return first;
  }

  if (last) {
    return last;
  }

  return tUnknownUser;
};

const Container = styled('div')(() => {
  return {
    display: 'grid',
    gap: '2.5rem',
  };
});

const WidgetBarContainer = styled('div')(({ theme }) => {
  return {
    alignItems: 'center',
    display: 'grid',
    gap: '2rem',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    [theme.breakpoints.down('sm')]: {
      gap: '1.5rem',
      gridAutoColumns: 'unset',
      gridAutoFlow: 'row',
      gridAutoRows: 'auto',
    },
  };
});

const WidgetBar = () => {
  return (
    <WidgetBarContainer>
      <Typography variant="h5">{tTitle}</Typography>
    </WidgetBarContainer>
  );
};

const ContactLink = styled(Link)(() => {
  return {
    '&:visited': { color: 'initial' },
    display: 'block',
    whiteSpace: 'nowrap',
  };
});

const Contact = ({ email, phone }: { email?: string | null; phone?: string | null }) => {
  return (
    <>
      {phone && <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>}
      {email && <ContactLink href={`mailto:${email}`}>{email}</ContactLink>}
    </>
  );
};

export interface EventRsvpRecord {
  begin: Date;
  contact?: {
    phone?: string;
    email?: string;
  };
  end: Date;
  name: string;
}

export interface EventRsvpTableProps {
  eventId: string;
}

export const EventRsvpTable = ({ eventId }: EventRsvpTableProps) => {
  const dateRange = useMemo(() => getFixedDateRange(), []);

  const handleClickRetry = useCallback(() => {
    refetch();
  }, []);

  const { data, isLoading, isError, refetch } = useEventUpcomingRsvpsQuery({
    end: dateRange.end.toISOString(),
    eventId,
    start: dateRange.start.toISOString(),
  });

  const records =
    data?.session?.eventUpcomingRsvps.map((record, index) => {
      return [
        getUserName(record.user.firstName, record.user.lastName),
        <Contact email={record.user.email} key={index} phone={record.user.phoneNumber} />,
        generateMeetingTime(record.meetingTime.beginMeetingTime, record.meetingTime.endMeetingTime),
      ];
    }) ?? [];

  return (
    <Container>
      <WidgetBar />
      <Table error={isError} headers={HEADERS} loading={isLoading} onClickRetry={handleClickRetry} records={records} />
    </Container>
  );
};
