import isEmpty from 'lodash/isEmpty';

export type RenderStatesProps<T> = Record<
  Exclude<Utils.QueryStatus, 'success'> | 'empty',
  React.ReactNode | (() => React.ReactNode)
> & {
  data?: T;
  status: Utils.QueryStatus;
  success: React.ReactNode | ((data: NonNullable<T>) => React.ReactNode);
};

/** Common query-status-based render states */
export function RenderStates<T>({ data, status, empty, error, pending, success }: RenderStatesProps<T>) {
  switch (status) {
    case 'error':
      return typeof error === 'function' ? error() : error;
    case 'pending':
      return typeof pending === 'function' ? pending() : pending;
    case 'success': {
      if (!data || isEmpty(data)) {
        return typeof empty === 'function' ? empty() : empty;
      }

      return typeof success === 'function' ? success(data) : success;
    }
    default:
      status satisfies never;
      throw new Error(
        `(RenderStates): Unknown status "${status}". Expected one of "empty", "error", "pending" or "success".`
      );
  }
}
