import { Send as SendIcon } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { KeyboardEvent, useRef, useState } from 'react';

import { AddMessage } from '../../hooks/useChat';

export const ChatIconButton = styled('button')({
  alignItems: 'flex-end',
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  height: '100%',
  margin: '0 -0.25rem 0 1rem',
  outline: 'none',
  padding: '0',
});

export interface ChatInputProps {
  handleMessage: AddMessage;
  placeholder: string;
}

export const ChatInput = ({ handleMessage, placeholder }: ChatInputProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const ref = useRef<HTMLElement>(null);

  const handleInput = () => {
    if (ref?.current?.innerText && isDisabled) {
      setIsDisabled(false);
    }
    if (!ref?.current?.innerText && !isDisabled) {
      setIsDisabled(true);
    }
  };

  const handleClick = () => {
    if (ref?.current) {
      handleMessage(ref.current.innerText);
      ref.current.innerText = '';
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    //checks whether the pressed key is "Enter"
    if (event.keyCode === 13) {
      event.preventDefault();
      handleClick();
    }
  };

  return (
    <Grid display="flex">
      <Typography
        sx={{
          alignSelf: 'flex-end',
          flex: '1',
          lineHeight: '1',
          marginBottom: '-0.125rem',
          width: 'calc(100% - 2.5rem)',
        }}
      >
        <Grid
          component="span"
          contentEditable
          data-cy="chat-input"
          onInput={handleInput}
          onKeyDown={onKeyDown}
          ref={ref}
          role="textbox"
          sx={{
            '&:empty::before': {
              color: 'grey',
              content: `"${placeholder}"`,
              display: 'block',
              fontStyle: 'oblique',
            },
            caretColor: 'grey',
            display: 'block',
            outline: 'none',
          }}
        />
      </Typography>
      <ChatIconButton disabled={isDisabled} onClick={handleClick}>
        <SendIcon
          fontSize="small"
          sx={{
            color: (theme) => theme.palette.primary.main,
            transform: 'rotate(-45deg)',
          }}
        />
      </ChatIconButton>
    </Grid>
  );
};

export default ChatInput;
