import { Alert, Avatar, Box, Button, Chip, Link, Typography } from '@mui/material';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

import { Member, MemberManagement, MemberManagementProps, RemovalConfirmDialog, RightSidebar } from '../../cc-ui';
import { useFeature } from '../../hooks/useFeature';
import { useDispatch } from '../../hooks/useTypedRedux';
import { useUserGetUserHistoryInformation } from '../../services/openapi/generated/CrowdCoursingComponents';
import { UserHistory } from '../../ui/molecules/UserHistory/UserHistory';
import FacilitatorGuard from '../FacilitatorGuard';

/** what we expect back from the member query */
interface MemberManagementQueryResults {
  members: Member[];
  requests: Member[];
}

/** callback to create the appropriate member redux actions */
type RequestActionCreator = (userId: string) => AsyncThunkAction<any, any, any>;

export interface MemberManagementContainerProps {
  isOwner?: boolean;
  heading: string;
  roomId?: string;
  eventId?: string;
  subHeading: string | undefined;
  approveRequestActionCreator: RequestActionCreator;
  addFacilitatorRequestActionCreator?: RequestActionCreator;
  addOwnerRequestActionCreator?: RequestActionCreator;
  removeFacilitatorRequestActionCreator?: RequestActionCreator;
  rejectRequestActionCreator: RequestActionCreator;
  removeMemberActionCreator: RequestActionCreator;
  query: () => {
    data?: MemberManagementQueryResults | null;
    loading?: boolean;
    isLoading?: boolean;
    refetch(): void;
  };
  removeDialog: {
    title: string;
    body: string;
    actionText: string;
  };
  onClick?: (m: Member) => void;
}

// User history slideout constants
const tNoUserSelected = 'No user selected';
const tPermissionError = 'Unable to view user history';
const tPermissionErrorDetails = "You may not have permission to view this user's history.";
const tRetry = 'Retry';
const tUserDetails = 'User Details';

/**
 * Container component wrapping `MemberManagement`, intended for rooms and
 * sessions
 *
 * Ensure the user is a facilitator, and then runs the given query, updating the
 * display after management actions are taken.
 */
export const MemberManagementContainer = ({
  isOwner,
  heading,
  subHeading,
  roomId,
  eventId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClick = () => {},
  ...props
}: MemberManagementContainerProps) => {
  const { data, isLoading, refetch } = props.query();
  const dispatch = useDispatch();
  const [pendingRemoval, setPendingRemoval] = useState<Member>();
  const [selectedMember, setSelectedMember] = useState<Member>();
  const [userId, setUserId] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const showVolunteerStats = useFeature('showVolunteerStats');

  // Fetch user history data - move this before any conditional returns
  const {
    data: userHistoryData,
    status,
    refetch: refetchUserHistory,
    error,
  } = useUserGetUserHistoryInformation(
    {
      pathParams: {
        userId,
      },
    },
    {
      // Only fetch data when we have a valid userId
      enabled: !!userId,
    }
  );

  // Check for errors - move this before any conditional returns
  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error]);

  if (isLoading || !data) return null;

  // create a callback that fires the action, then refreshes the query when it
  // completes
  const dispatchAndRefresh = (actionCreator: RequestActionCreator) => (user: Member) =>
    dispatch(actionCreator(user.id)).then(refetch);

  const { members, requests } = data;

  // Handle member click to show the user history
  const handleMemberClick = (member: Member) => {
    setSelectedMember(member);
    setUserId(member.id);
    setHasError(false);
  };

  const handleCloseSlideout = () => {
    setSelectedMember(undefined);
    setUserId('');
  };

  // Function to handle retry
  const handleRetry = () => {
    setHasError(false);
    refetchUserHistory();
  };

  const memberProps: MemberManagementProps = {
    eventId,
    heading,
    isOwner,
    members,
    onAddFacilitator: props.addFacilitatorRequestActionCreator
      ? dispatchAndRefresh(props.addFacilitatorRequestActionCreator)
      : () => {},
    onAddOwner: props.addOwnerRequestActionCreator ? dispatchAndRefresh(props.addOwnerRequestActionCreator) : () => {},
    onApprove: dispatchAndRefresh(props.approveRequestActionCreator),
    onClick: handleMemberClick,
    onReject: dispatchAndRefresh(props.rejectRequestActionCreator),
    onRemove: setPendingRemoval,
    onRemoveFacilitator: props.removeFacilitatorRequestActionCreator
      ? dispatchAndRefresh(props.removeFacilitatorRequestActionCreator)
      : () => {},
    requests,
    roomId,
    subHeading,
  };

  const dialogProps = {
    ...props.removeDialog,
    isOpen: pendingRemoval !== undefined,
    onCancel: () => setPendingRemoval(undefined),
    onConfirm: async () => {
      if (pendingRemoval) {
        await dispatchAndRefresh(props.removeMemberActionCreator)(pendingRemoval);
        setPendingRemoval(undefined);
      }
    },
  };

  const userName = selectedMember ? `${selectedMember.lastName}, ${selectedMember.firstName}` : tNoUserSelected;

  return (
    <>
      <RemovalConfirmDialog {...dialogProps} />
      <MemberManagement {...memberProps} />
      <RightSidebar
        headingText={tUserDetails}
        label={() => null}
        onClose={handleCloseSlideout}
        open={Boolean(selectedMember)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            pb: 4,
          }}
        >
          {selectedMember && (
            <>
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '1rem' }}>
                <Avatar
                  alt={userName || undefined}
                  src={userHistoryData?.profileImageUri || undefined}
                  sx={{ height: 72, width: 72 }}
                />
                <Box>
                  <Typography sx={{ mb: 0.25 }} variant="h6">
                    {userName}
                  </Typography>
                  {selectedMember.roles && selectedMember.roles.length > 0 && (
                    <Box sx={{ mb: 0.25 }}>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedMember.roles.map((role) => (
                          <Chip key={role} label={role} size="small" />
                        ))}
                      </Box>
                    </Box>
                  )}
                  <Typography color="text.secondary" sx={{ mb: 0.25 }} variant="body2">
                    {userHistoryData?.email ? (
                      <Link
                        href={`mailto:${userHistoryData.email}`}
                        sx={{
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                          color: 'primary.main',
                          textDecoration: 'none',
                        }}
                      >
                        {userHistoryData.email}
                      </Link>
                    ) : (
                      'No email provided'
                    )}
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    {userHistoryData?.phoneNumber}
                  </Typography>
                </Box>
              </Box>

              {hasError ? (
                <Box sx={{ p: 2 }}>
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    {tPermissionError}
                    <Typography sx={{ mt: 1 }} variant="body2">
                      {tPermissionErrorDetails}
                    </Typography>
                  </Alert>
                  <Button onClick={handleRetry} variant="outlined">
                    {tRetry}
                  </Button>
                </Box>
              ) : (
                <UserHistory
                  data={userHistoryData}
                  showUserUpcomingRsvps={true}
                  showVolunteerStats={showVolunteerStats}
                  status={status}
                />
              )}
            </>
          )}
        </Box>
      </RightSidebar>
    </>
  );
};

/**
 * a MemberManagementContainer that ensures the current user facilitators the
 * current pod/room */
export const FacilitatedMemberManagementContainer = (props: MemberManagementContainerProps) => {
  return (
    <FacilitatorGuard>
      <MemberManagementContainer {...props} />
    </FacilitatorGuard>
  );
};

export default FacilitatedMemberManagementContainer;
