import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useParams } from 'react-router-dom';

import { useSVG } from '../../hooks/useSVG';
import { useRoomQrQuery } from '../../services/graphql/generated/react-query';
import { QRCodeTemplate } from '../../ui/templates/CheckIn/QRCodeTemplate';

dayjs.extend(duration);

const tScanQRCode = 'Scan the code above to join the room and check in';
const tInstructions = [
  '• If not logged in, they will be directed to log in first',
  '• After logging in, they will be automatically checked in',
  '• They will receive a confirmation notification',
];

/**
 * CheckInCode is a bare-bones display of the check-in QR code, intended to be
 * scanned by volunteers on site, and printed by facilitators.
 */
export const CheckInCode = () => {
  const { roomId } = useParams();
  const { data } = useRoomQrQuery(
    { roomId: roomId ?? '' },
    { staleTime: dayjs.duration(10, 'minutes').milliseconds() }
  );
  const svg = useSVG(data?.room?.checkInImageUri);

  if (!data?.room || !svg) {
    return null;
  }

  return <QRCodeTemplate instructions={tInstructions} qrCodeSrc={svg} subtitle={tScanQRCode} title={data.room.name} />;
};
