import { Grid, Skeleton, Typography, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useState } from 'react';

import { useBulkUploadSheetValidation, useSheetReader, WorksheetGeneral } from '../../hooks';
import { FileUpload, FileUploadProps } from '../FileUpload';
import { BulkUploadPreview } from '../Tables';

const tExampleSheet = 'Download Template';
const tErrorText = 'Errors In Sheet';

const DOWNLOAD_LINK = '/assets/bulk_upload_template.xlsx';

const LoadingIndicator = styled(Skeleton)(({ theme }) => ({
  border: `1px solid ${theme.palette.crowdCoursing.ORANGE['100']?.main ?? 'initial'}`,
  height: '397px',
  position: 'absolute',
  width: '100%',
  zIndex: 1,
}));

export interface BulkUploadProps {
  schools?: string[];
  onSubmit: (worksheet?: WorksheetGeneral) => FileUploadProps['onSubmit'];
  /**
   * Callback fired when upload is successful, used to close accordion
   */
  onSuccess?: () => void;
  /**
   * Whether to show the template button in FileUpload
   * @default true
   */
  showTemplateButton?: boolean;
}

export const BulkUpload = ({ onSubmit, schools, onSuccess, showTemplateButton = true }: BulkUploadProps) => {
  const [files, setFiles] = useState<FileList | null>(null);
  const [fileUploadKey, setFileUploadKey] = useState<number>(0);

  const {
    isLoading,
    error: sheetReaderError,
    onFilesReceived,
    worksheets,
    resetWorksheets,
  } = useSheetReader({
    emptyCellDefaultValue: '',
    hasHeaderRow: true,
    rowFormat: 1,
    shouldIncludeBlankRows: false,
  });

  const worksheet = worksheets ? (worksheets[0] as WorksheetGeneral) : undefined;

  // todo: lift validation logic out of bulk upload component
  const { errors: validationErrors } = useBulkUploadSheetValidation({ schools, worksheet });

  // Forwards the submission to the parent component and resets the files after submission
  const handleSubmit: FileUploadProps['onSubmit'] = useCallback(
    (files: FileList | null) => {
      // Call the parent's onSubmit with the current worksheet
      const result = onSubmit(worksheet);

      // Reset all state to allow for new file uploads
      resetWorksheets();

      // Clear the files state to remove the filename display
      setFiles(null);

      // Force complete re-render of file input
      setFileUploadKey((prev) => prev + 1);

      // Notify parent component of successful upload (to close accordion)
      if (onSuccess) {
        onSuccess();
      }

      // Return the result of the parent's onSubmit for the FileUpload component
      return result?.(files);
    },
    [onSubmit, worksheet, resetWorksheets, onSuccess]
  );

  // Custom handler for file changes to ensure state is properly updated
  const handleFileChange = useCallback(
    (options: { files: FileList | null; isAcceptedType?: boolean }) => {
      // Reset existing worksheets when a new file is selected
      if (options.files) {
        resetWorksheets();
      }

      // Call the sheet reader's file handler
      onFilesReceived(options);
    },
    [onFilesReceived, resetWorksheets]
  );

  return (
    <FileUpload
      acceptFileTypes="spreadsheet"
      error={validationErrors.length > 0 ? { errors: validationErrors, text: tErrorText } : undefined}
      example={showTemplateButton ? { link: DOWNLOAD_LINK, name: tExampleSheet } : undefined}
      files={files}
      fullWidth
      isLoading={isLoading}
      key={fileUploadKey}
      onChange={handleFileChange}
      onSubmit={handleSubmit}
      setFiles={setFiles}
    >
      {worksheet && (
        <Grid container marginTop="2rem" maxWidth="100%" position="relative">
          {isLoading && <LoadingIndicator variant="rectangular" />}
          {sheetReaderError && <Typography>{sheetReaderError}</Typography>}
          {!sheetReaderError && (
            <Zoom in={!isLoading && !!worksheet}>
              <Grid container direction="row">
                <BulkUploadPreview worksheet={worksheet} />
              </Grid>
            </Zoom>
          )}
        </Grid>
      )}
    </FileUpload>
  );
};
