import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button } from '@mui/material';
import { MouseEvent, ReactNode, useLayoutEffect, useRef, useState } from 'react';

const tMore = 'View More';
const tLess = 'View Less';

// todo: figure out how to animate this
const CollapsibleText = ({ children }: { children: ReactNode }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [showButton, setShowButton] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickButton = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  useLayoutEffect(() => {
    if (containerRef.current) {
      // hide the button if there is no overflow
      const { offsetHeight, scrollHeight } = containerRef.current;
      if (offsetHeight === scrollHeight) {
        setShowButton(false);
      }
    }
  }, [containerRef]);

  return (
    <div>
      <Box
        ref={containerRef}
        sx={
          isCollapsed
            ? {
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '3',
                display: '-webkit-box',
                overflow: 'hidden',
              }
            : {}
        }
      >
        {children}
      </Box>
      {showButton && (
        <Button
          color="inherit"
          onClick={handleClickButton}
          size="small"
          startIcon={isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          sx={{ float: 'right' }}
          variant="text"
        >
          {isCollapsed ? tMore : tLess}
        </Button>
      )}
    </div>
  );
};

export default CollapsibleText;
