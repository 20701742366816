import { Box } from '@mui/material';

interface QRCodeImageProps {
  src: string;
}

export const QRCodeImage = ({ src }: QRCodeImageProps) => {
  return (
    <Box
      alt="QR Code"
      component="img"
      src={src}
      sx={{
        height: 'auto',
        maxWidth: '300px',
        width: '100%',
      }}
    />
  );
};
