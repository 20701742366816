import { Button, ButtonBase, Fade, Grid, Link, List, ListItem, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRef, useState } from 'react';

import { Browser } from '../../../utilities/browser';
import { Dialog } from '../Dialog';
import { Dropzone, DROPZONE_ID } from './Dropzone';
import { FileName, Text } from './FileName';
import { FilePreview } from './FilePreview';

const tCancel = 'Cancel';
const tSubmit = 'Submit';
const tDragAndDrop = 'Drag and Drop or Click to Choose File';
const tClick = 'Click to Choose File';
const tClose = 'Close';

export const INPUT_ID = 'CrowdCoursing-file-input';

export const FlexContainer = styled('div', {
  shouldForwardProp: (prop) => !['fullWidth'].includes(prop.toString()),
})<React.HTMLProps<HTMLDivElement> & { fullWidth?: boolean }>(({ fullWidth }) => {
  const style: Partial<React.CSSProperties> = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: fullWidth ? '100%' : 'fit-content',
  };

  return style;
});

export const GridContainer = styled(ButtonBase)(({ theme }) => ({
  display: 'grid',
  fontWeight: 'normal',
  gridTemplateAreas: "'file-upload-grid-item-preview' 'file-upload-grid-item-text'",
  gridTemplateColumns: 'auto',
  gridTemplateRows: '1fr auto',
  height: '224px',
  maxHeight: '224px',
  minWidth: '318px',
  padding: theme.spacing(1),
  rowGap: theme.spacing(2),
}));

export const GridItemPreview = styled('div')({
  alignSelf: 'center',
  gridArea: 'file-upload-grid-item-preview',
  justifySelf: 'center',
  position: 'relative',
});
export const GridItemText = styled('div')({ gridArea: 'file-upload-grid-item-text', justifySelf: 'center' });

export const UploadInput = styled('input')({ display: 'none' });

const LoadingIndicator = styled(Skeleton)(() => ({
  height: '224px',
  position: 'absolute',
  width: '100%',
  zIndex: -1,
}));

type FileType = 'spreadsheet' | 'image' | 'video' | 'text' | 'any';

const SPREAD_SHEET =
  '.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.msexcel,application/excel';
const IMAGE = 'image/*';
const VIDEO = 'video/*';
const TEXT = 'text/*';

const getAccept = (fileType?: FileType) => {
  switch (fileType) {
    case 'image':
      return IMAGE;
    case 'video':
      return VIDEO;
    case 'text':
      return TEXT;
    case 'spreadsheet':
      return SPREAD_SHEET;
    default:
      return '*';
  }
};

const isValidType = (expected: string, actual: string) => {
  // '*' means accept any file type
  if (expected === '*' || expected === '') {
    return true;
  }

  // Check file type categories
  if (expected === IMAGE) {
    return actual.startsWith('image/');
  }
  if (expected === VIDEO) {
    return actual.startsWith('video/');
  }
  if (expected === TEXT) {
    return actual.startsWith('text/');
  }
  if (expected === SPREAD_SHEET) {
    // Need special handling for spreadsheet which is a comma-separated list
    const acceptedTypes = expected.split(',');
    return acceptedTypes.some((type) => {
      // Handle mime type patterns with wildcards (e.g., 'application/*')
      if (type.endsWith('/*')) {
        const prefix = type.split('/*')[0];
        return actual.startsWith(prefix + '/');
      }
      // Handle extensions (e.g., '.xlsx')
      if (type.startsWith('.')) {
        const extension = type.substring(1);
        return actual.endsWith('/' + extension) || actual.includes('.' + extension);
      }
      // Direct MIME type comparison
      return type === actual;
    });
  }

  // For comma-separated values, split and check each
  const acceptedTypes = expected.split(',');
  return acceptedTypes.some((type) => {
    // Handle MIME type patterns with wildcards (e.g., 'image/*')
    if (type.endsWith('/*')) {
      const prefix = type.split('/*')[0];
      return actual.startsWith(prefix + '/');
    }
    // Handle extensions (e.g., '.jpg')
    if (type.startsWith('.')) {
      const extension = type.substring(1);
      return actual.endsWith('/' + extension) || actual.includes('.' + extension);
    }
    // Direct MIME type comparison
    return type === actual;
  });
};

export interface OnChangeOptions {
  files: FileList | null;
  isAcceptedType?: boolean;
}

export interface FileUploadProps {
  files: FileList | null;
  setFiles: (files: FileList | null) => void;
  acceptFileTypes?: FileType;
  children?: React.ReactNode;
  example?: {
    name: string;
    link: string;
  };
  error?: {
    text: string;
    errors: string[];
  };
  fullWidth?: boolean;
  isLoading?: boolean;
  onChange?: (options: OnChangeOptions) => void;
  onSubmit?: (files: FileList | null) => void;
}

export const FileUpload = ({
  files,
  setFiles,
  acceptFileTypes,
  children,
  example,
  error,
  fullWidth,
  isLoading,
  onChange,
  onSubmit,
}: FileUploadProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  const updateFiles = ({ files, isAcceptedType }: OnChangeOptions) => {
    setFiles(files);
    if (onChange) {
      onChange({ files, isAcceptedType });
    }
  };

  const accept = getAccept(acceptFileTypes);
  const hasErrors = Boolean(files && error?.errors && error.errors.length > 0);

  const handleReceiveFiles = (files: FileList | null) => {
    if (!files || files.length === 0) {
      updateFiles({ files: null });
      return;
    }

    const isAcceptedType = isValidType(accept, files[0].type);

    // Always set files, even if not accepted type
    updateFiles({ files, isAcceptedType });
  };

  const handleCancel = () => {
    /*
        the input value has to be cleared to allow a user to select the same file after canceling.

        1. select file `foo.xls`
        2. cancel file `foo.xls`
        3. select file `foo.xls`
            a. `onChange` won't run if we don't clear the input value
    */
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    updateFiles({ files: null });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleReceiveFiles(event.target.files);
  };

  const handleClickErrorLink = () => {
    if (error && error.errors.length) {
      setIsDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    if (files && onSubmit) {
      onSubmit(files);
      setFiles(null);

      // We reset the controlled state, but we also need to reset the input value
      // to allow the user to select the same file again after canceling.
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  return (
    <>
      <FlexContainer
        data-testid="file-upload-flex-container"
        fullWidth={fullWidth}
        style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
      >
        <Grid marginTop="1em">
          <Dropzone
            onChange={handleReceiveFiles}
            onSubmit={handleSubmit}
            slotProps={{
              form: {
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                },
              },
            }}
          >
            {isLoading && <LoadingIndicator data-testid="file-upload-loading" variant="rectangular" />}
            <GridContainer
              aria-labelledby={INPUT_ID}
              data-testid="file-upload-container"
              onClick={() => inputRef.current?.click()}
              ref={(el) => el && setContainerWidth(el.clientWidth)}
              style={{ width: '100%' }}
            >
              <GridItemPreview>
                <Grid position="relative" width="100%">
                  <FilePreview files={files} />
                </Grid>
              </GridItemPreview>
              <GridItemText>
                <Text fontStyle="italic" variant="body2">
                  {Browser.hasDragAndDropEvents ? tDragAndDrop : tClick}
                </Text>
              </GridItemText>
            </GridContainer>
            <UploadInput
              accept={accept}
              id={INPUT_ID}
              name={INPUT_ID}
              onChange={handleChange}
              ref={inputRef}
              type="file"
            />
          </Dropzone>
          <Grid marginTop="0.5rem" maxWidth={containerWidth ?? 'auto'}>
            <FileName files={files} />
          </Grid>

          {example && (
            <Link download href={example.link}>
              {example.name}
            </Link>
          )}
        </Grid>
        {children}
        <Fade in={!!files && !isLoading}>
          <Grid alignItems="center" display="flex" gap="2rem" justifyContent={hasErrors ? 'space-between' : 'flex-end'}>
            {hasErrors && (
              <Link
                color="#FF3616"
                data-testid="file-upload-error-link"
                fontWeight="bold"
                onClick={handleClickErrorLink}
                style={{
                  cursor: 'pointer',
                }}
              >
                {error?.text}
              </Link>
            )}

            <div style={{ marginBottom: '1.5rem' }}>
              <Button
                data-testid="file-upload-cancel-button"
                name="Cancel"
                onClick={handleCancel}
                sx={{ marginRight: '0.5rem' }}
              >
                {tCancel}
              </Button>
              <Button
                color="primary"
                data-testid="file-upload-submit-button"
                disabled={hasErrors}
                form={DROPZONE_ID}
                name="Submit"
                onClick={handleSubmit}
                sx={{ minWidth: '72px' }}
                type="submit"
                variant="contained"
              >
                {tSubmit}
              </Button>
            </div>
          </Grid>
        </Fade>
      </FlexContainer>
      <Dialog
        cancelProps={{ text: tClose }}
        data-testid="file-upload-error-dialog"
        onDialogClose={handleDialogClose}
        open={isDialogOpen}
        submitProps={{ isHidden: true }}
        title={error?.text}
      >
        <List>
          {error?.errors?.map((message, index) => (
            <ListItem key={`${index}-${message}`}>
              <Typography>{message}</Typography>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
};
